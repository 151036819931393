import React, { useState, useEffect } from 'react';
import './Feedback.css';
import Rectangle from "../../Image/Rectangle.png"
import Rectangle1 from "../../Image/Rectangle (1).png"

function FeedbackDisplay() {

  const [feedbackData, setFeedbackData] = useState([]);
  const initialSelectedIndex = 0;
  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);
  const [isFeedbackChanging, setIsFeedbackChanging] = useState(false);

  useEffect(() => {
    setIsFeedbackChanging(true);
    const apiUrl = 'https://e-learning-backend-seven.vercel.app/get-all-feedback';
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setFeedbackData(data.data.slice(1, 4)); 

        setIsFeedbackChanging(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsFeedbackChanging(false);
      });
  }, [selectedIndex]);

  const handleCardClick = (index) => {
    setSelectedIndex(index);
  };

  const selectedFeedback = feedbackData[selectedIndex];

  return (
    <div className="feedback-container">
      <div className="user-list">
        <div className='user-list-title'>
        <h1>Feedback Bonaza</h1>
        </div>
       <div className='user-list-content'>{feedbackData.map((feedback, index) => (
          <div
            key={index}
            className={`user-card ${selectedIndex === index ? 'selected' : ''}`}
            onClick={() => handleCardClick(index)}
          >
            <div className="user-profile">
              <img
                src={feedback.profileURL || 'https://cdn3d.iconscout.com/3d/premium/thumb/spaceman-6201029-5084795.png'}
                alt="Profile"
              />
            </div>
            <div className="user-details">
              <h1>{feedback.firstName}</h1>
             
              <h3>{feedback.message.substring(0, 75)}...</h3>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M25.7075 15.2926L13.7075 3.29256C13.5676 3.15254 13.3894 3.05718 13.1953 3.01853C13.0012 2.97988 12.8 2.99968 12.6172 3.07543C12.4344 3.15119 12.2782 3.27948 12.1683 3.44407C12.0584 3.60867 11.9998 3.80216 12 4.00006V9.00006H2C1.46957 9.00006 0.960859 9.21077 0.585786 9.58584C0.210714 9.96092 0 10.4696 0 11.0001V21.0001C0 21.5305 0.210714 22.0392 0.585786 22.4143C0.960859 22.7893 1.46957 23.0001 2 23.0001H12V28.0001C11.9998 28.198 12.0584 28.3914 12.1683 28.556C12.2782 28.7206 12.4344 28.8489 12.6172 28.9247C12.8 29.0004 13.0012 29.0202 13.1953 28.9816C13.3894 28.9429 13.5676 28.8476 13.7075 28.7076L25.7075 16.7076C25.8005 16.6147 25.8742 16.5044 25.9246 16.383C25.9749 16.2616 26.0008 16.1315 26.0008 16.0001C26.0008 15.8686 25.9749 15.7385 25.9246 15.6171C25.8742 15.4957 25.8005 15.3854 25.7075 15.2926ZM14 25.5863V22.0001C14 21.7348 13.8946 21.4805 13.7071 21.2929C13.5196 21.1054 13.2652 21.0001 13 21.0001H2V11.0001H13C13.2652 11.0001 13.5196 10.8947 13.7071 10.7072C13.8946 10.5196 14 10.2653 14 10.0001V6.41381L23.5863 16.0001L14 25.5863Z" fill="white"/>
</svg>
          </div>
        ))}</div>
      </div>
      <div className="feedback-details">
        <div className='Rectangle'>
          <img src={Rectangle} alt='Rectangle' />
        </div>
        {selectedFeedback && (
          <div className={`feedback-content ${isFeedbackChanging ? 'fade-in' : ''}`}>
               <img
                src={selectedFeedback.profileURL || 'https://cdn3d.iconscout.com/3d/premium/thumb/spaceman-6201029-5084795.png'}
                alt="Profile"
              />
            <h1>{selectedFeedback.title}</h1>
            <p>{selectedFeedback.message}</p>
          </div>
        )}
        <div className='Rectangle-1'>
        <img src={Rectangle1} alt='Rectangle' />
        </div>
      </div>
    </div>
  );
}

export default FeedbackDisplay;
