import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './GetArticle.css';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';

function ArticleList() {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const { _id } = useParams();

  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    const apiUrl = `https://e-learning-backend-seven.vercel.app/get-treatise/${_id}`;
    axios
      .get(apiUrl)
      .then((response) => {
        const sortedArticles = response.data.data;
        setArticles(sortedArticles ? [sortedArticles] : []);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error);
      });
  }, [_id]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const copyCodeToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand("copy");
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  };
  console.log(articles,"sortedArticles");
  return (
    <div className='Article-container-main'>
        
      {articles?.map((article) => (
        <div className='Article-container' key={article._id}>
          <div className='Article-title' style={{color:'black'}}>
            <h1>{article?.title[0].heading}</h1>
          </div>
          {article.title[0]?.content && (
            <div className='Article-content'>
              <p>{article.title[0].content}</p>
            </div>
          )}

          <div className='subheading'>
            {article.subHeadings[0]?.order && (
              <div className='Article-title-model-1' style={{ order: article.subHeadings[0].order }}>
                <div className="image-1">
                  {article.link[0]?.url && (
                    <img src={article.link[0].url} alt='profile' />
                  )}
                </div>
                <div className="para-1">
                   {article.subHeadings[0]?.content && (
                    <p>{article.subHeadings[0].content}</p>
                  )}
                </div>
              </div>
            )}

       
            {article.subHeadings[1]?.order && (
              <div className='Article-title-model-1' style={{ order: article.subHeadings[1].order }}>
                <div className="para-2">
                  {article.subHeadings[1]?.content && (
                    <p>{article.subHeadings[1].content}</p>
                  )}
                </div>
                <div className="image-2">
                  {article.link[0]?.url && (
                    <img src={article.link[0].url} alt='profile' />
                  )}
                </div>
              </div>
            )}
            {article.subHeadings[2]?.order && (
              <div className="listitem1" style={{ order: article.subHeadings[2].order }}>
                <div className='paragraph-1'>
                  {article.subHeadings[2]?.heading && (
                    <h2>{article.subHeadings[2].heading}</h2>
                  )}
                  {article.subHeadings[2]?.content && (
                    <p>{article.subHeadings[2].content}</p>
                  )}
                </div>
              </div>
            )}

           
            {article.subHeadings[3]?.order && (
              <div className="listitem2" style={{ order: article.subHeadings[3].order }}>
                <div className='paragraph-1'>
                  {article.subHeadings[3]?.heading && (
                    <h2>{article.subHeadings[3].heading}</h2>
                  )}
                  {article.subHeadings[3]?.content && (
                    <p>{article.subHeadings[3].content}</p>
                  )}
                </div>
              </div>
            )}

           
            {article.subHeadings[4]?.order && (
              <div className="listitem3" style={{ order: article.subHeadings[4].order }}>
                <div className='paragraph-1'>
                  {article.subHeadings[4]?.heading && (
                    <h2>{article.subHeadings[4].heading}</h2>
                  )}
                  {article.subHeadings[4]?.content && (
                    <p>{article.subHeadings[4].content}</p>
                  )}
                </div>
              </div>
            )}

          
            {article.subHeadings[5]?.order && (
              <div className="listitem4" style={{ order: article.subHeadings[5].order }}>
                <div className='Article-title-model-2'>
                  {article.lists[0]?.heading && (
                    <h2>{article.lists[0]?.heading}</h2>
                  )}
                  <ul>
                    {article.lists[0]?.content && (
                      <li><p>{article.lists[0].content}</p></li>
                    )}
                    {article.lists[1]?.heading && (
                      <h3>{article.lists[1]?.heading}</h3>
                    )}
                    {article.lists[1]?.content && (
                      <li><p>{article.lists[1].content}</p></li>
                    )}
                    {article.lists[2]?.heading && (
                      <h3>{article.lists[2]?.heading}</h3>
                    )}
                    {article.lists[2]?.content && (
                      <li><p>{article.lists[2].content}</p></li>
                    )}
                    {article.lists[3]?.heading && (
                      <h3>{article.lists[3]?.heading}</h3>
                    )}
                    {article.lists[3]?.content && (
                      <li><p>{article.lists[3].content}</p></li>
                    )}
                    {article.lists[4]?.heading && (
                      <h3>{article.lists[4]?.heading}</h3>
                    )}
                    {article.lists[4]?.content && (
                      <li><p>{article.lists[4].content}</p></li>
                    )}
                    {article.lists[5]?.heading && (
                      <h3>{article.lists[5]?.heading}</h3>
                    )}
                    {article.lists[5]?.content && (
                      <li><p>{article.lists[5].content}</p></li>
                    )}
                    {article.lists[6]?.heading && (
                      <h3>{article.lists[6]?.heading}</h3>
                    )}
                    {article.lists[6]?.content && (
                      <li><p>{article.lists[6].content}</p></li>
                    )}
                    {article.lists[7]?.heading && (
                      <h3>{article.lists[7]?.heading}</h3>
                    )}
                    {article.lists[7]?.content && (
                      <li><p>{article.lists[7].content}</p></li>
                    )}
                  </ul>
                </div>
              </div>
            )}

            {article.subHeadings[6]?.order && (
              <div className="listitem5" style={{ order: article.subHeadings[6].order }}>
                <div className='Article-list-model-2'>
                  {article.lists[8]?.heading && (
                    <h2>{article.lists[8]?.heading}</h2>
                  )}
                  <ol>
                    {article.lists[8]?.content && (
                      <li><p>{article.lists[8].content}</p></li>
                    )}
                    {article.lists[9]?.heading && (
                      <h3>{article.lists[9]?.heading}</h3>
                    )}
                    {article.lists[9]?.content && (
                      <li><p>{article.lists[9].content}</p></li>
                    )}
                    {article.lists[10]?.heading && (
                      <h3>{article.lists[10]?.heading}</h3>
                    )}
                    {article.lists[10]?.content && (
                      <li><p>{article.lists[10].content}</p></li>
                    )}
                    {article.lists[11]?.heading && (
                      <h3>{article.lists[11]?.heading}</h3>
                    )}
                    {article.lists[11]?.content && (
                      <li><p>{article.lists[11].content}</p></li>
                    )}
                    {article.lists[12]?.heading && (
                      <h3>{article.lists[12]?.heading}</h3>
                    )}
                    {article.lists[12]?.content && (
                      <li><p>{article.lists[12].content}</p></li>
                    )}
                    {article.lists[13]?.heading && (
                      <h3>{article.lists[13]?.heading}</h3>
                    )}
                    {article.lists[13]?.content && (
                      <li><p>{article.lists[13].content}</p></li>
                    )}
                    {article.lists[14]?.heading && (
                      <h3>{article.lists[14]?.heading}</h3>
                    )}
                    {article.lists[14]?.content && (
                      <li><p>{article.lists[14].content}</p></li>
                    )}
                    {article.lists[15]?.heading && (
                      <h3>{article.lists[15]?.heading}</h3>
                    )}
                    {article.lists[15]?.content && (
                      <li><p>{article.lists[15].content}</p></li>
                    )}
                  </ol>
                </div>
              </div>
            )}

         
            {article.subHeadings[7]?.order && (
              <div className="listitem6" style={{ order: article.subHeadings[7].order }}>
                {article.subHeadings && article.subHeadings.length > 0 && (
                  <div className="textarea-container">
                    <textarea
                      ref={textAreaRef}
                      className='textarea-getArticleText'
                      value={article.subHeadings[7]?.content || ''} 
                    />
                    {copySuccess ? (
                      <div className="copy-success">Copied!</div>
                    ) : (
                      <button className="copy-button" onClick={copyCodeToClipboard}>
                        Copy
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}

         
            {article.subHeadings[8]?.order && (
              <div className="listitem7" style={{ order: article.subHeadings[8].order }}>
                <div className='channel'>
                  {article.link[3]?.url && (
                    <ReactPlayer
                      url={article.link[3].url}
                      playing
                      controls
                    />
                  )}
                </div>
              </div>
            )}
            {article.subHeadings[9]?.order && (
              <div className="listitem8" style={{ order: article.subHeadings[8].order }}>
                {article.subHeadings[9]?.content && (
                  <p> " {article.subHeadings[9].content} "</p>
                )}
              </div>
            )}
            {article.subHeadings[10]?.order && (
              <div className="listitem9" style={{ order: article.subHeadings[10].order }}>
                {article.subHeadings[10]?.content && (
                  <p>{article.subHeadings[10].content}</p>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ArticleList;


