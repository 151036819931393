import React from 'react'
import "./Gethelp.css"
import image1 from "../../Image/Vector (15).png"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import Footer from '../../Footer/Footer'
import useScrollToTop from '../../Hooks/useScrollToTop'
function Gethelp() {


  useScrollToTop()
  return (
   <div>
      <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='gethelp-container'>
    <div className='faq-btn-C'>
    <div className='Faq'>
      <p className='text-faq'>FAQ</p>
      <p className='faq-p'>Frequently asked questions in Inocyx E learning</p>
    </div>
    <div>
      <Link to='/FAQ'>
      <button className='gethelp-btn'><img src={image1}  className='gethelp-btn-img'></img></button>
      </Link>
    
    </div>
    </div>
    <div className='faq-btn-C'>
   <div className='Faq'>
   <p className='text-faq'>Raise a Ticket</p>
   <p className='faq-p'>Frequently asked questions in Inocyx E learning</p>
   </div>
   <div>
    <Link to="/Raiseticket">
    <button className='gethelp-btn'><img src={image1}  className='gethelp-btn-img'></img></button>
    </Link>
  
   </div>
    </div>
    </div>
    <Footer/>
   </div>
  )
}

export default Gethelp
