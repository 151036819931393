import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import imag from '../Image/Frame 5616 (2).png';
import useScrollDirection from "../UseScrollDirection";
import { BASE_URL } from "../Constants";
import "./NavBar.css";

const Nav = () => {
 
  const [email, setEmail] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const checkToken = localStorage.getItem("token");
        if (checkToken) {
          const url = 'https://e-learning-backend-seven.vercel.app/dashboard-profile';
          const emailFromLocal = localStorage.getItem("email");
        
          if (emailFromLocal) {
            const response = await fetch(url, {
              method: 'POST',
              body: JSON.stringify({ "email": emailFromLocal }),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${checkToken}`
              }
            });
            const data = await response.json();
            setUser(data?.data);
           
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
  
 
  useEffect(() => {
    const emailFromLocal = localStorage.getItem("email");

    console.log(emailFromLocal, "profile")

    if (emailFromLocal) {
      setEmail(emailFromLocal);
    }
  }, []);

  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false); 
  const scrollDirection = useScrollDirection();

  const changecolor = () => {
    if (window.scrollY >= 70) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    changecolor(); 
    window.addEventListener("scroll", changecolor);
    return () => {
      window.removeEventListener("scroll", changecolor);
    };
  }, []);

  const handleClick = () => setClick(!click);

  const profilePic = user?.length > 0 ? user[0]?.profileURL : null;

  localStorage.setItem("profilePic", profilePic);

  return (
    <nav className={`header ${color ? 'header-blue' : ''} ${scrollDirection === 'down' ? 'hidden' : ''}`} >
      <div className="nav-container">
        <Link to="/"><img src={imag} className='nav-logo' alt='logo' /></Link>

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
            to='/'
              className='list-items'
              activeClassName="active"
              onClick={handleClick}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <a
              href="#PLANS"
              className='list-items'
              activeClassName="active"
              onClick={handleClick}
            >
             Plans
            </a>
            <ul className="plans-dropdown">
        <li> <Link  to='/PlansDetails/6489b5b48724a7fc3aab1492' style={{ textDecoration: 'none' }}>beginners Level</Link></li>
        <li><Link to='/PlansDetails/6489b7cd8724a7fc3aab1495' style={{ textDecoration: 'none' }}>Intermediate Level</Link></li>
        <li> <Link to='/PlansDetails/6489b7df8724a7fc3aab1498' style={{ textDecoration: 'none' }}>Advanced Level</Link></li>
      </ul>
          </li>
          <li className="nav-item"   >
            <Link to='Articles' activeClassName="active"  className='list-items'  onClick={handleClick}>Article</Link>
            {/* <ul className="plans-dropdown">
        <li><a href="#0">Tier 1</a></li>
        <li><a href="#0">Tier 2</a></li>
        <li><a href="#0">Tier 3</a></li>
      </ul> */}
          </li>
          <li className="nav-item"   >
            <Link to='/Jargons' activeClassName="active"  className='list-items'  onClick={handleClick}>Jargons</Link>
          </li>
          <li className="nav-item">
            <Link
              exact
              to='/About'
              activeClassName="active"
              className='list-items'
              onClick={handleClick}
            >
           About
            </Link>
          </li>
          {email ? (
            <li className='avator-logo' >
              <Link to="/userprofile">
                <img src={profilePic || 'https://cdn3.iconfinder.com/data/icons/business-round-flat-vol-1-1/36/user_account_profile_avatar_person_student_male-512.png'} alt='ProfileImage' width="50px" height="50px" className="profile-image-nav" />
              </Link>
            </li>
          ) : (
            <a href='/SignIn' style={{color: "white"}}>        
              <div className='avator'>
                <div className="signIn-btn">
                  <div className='ul-2-li'>
                    <p>SignIn</p>
                  </div>
                </div>
              </div>
            </a>
          )}
        </ul>
       
        <div className="nav-icon" onClick={handleClick}>
          {click ? <CloseIcon sx={{width:"4vw", height:"4vh"}} />  : <MenuIcon sx={{width:"4vw", height:"4vh"}} />}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
