import React, { useState, useEffect } from "react";
import axios from "axios";
import certificate from "../../../Images/certificate.png";
import logo from "../../../Images/inocyxlogo.png";
import "./Certificate.css";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

function Certificate() {
  const [certificateData, setCertificateData] = useState(null);
  const { certificateID } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Add state to track data loading

  useEffect(() => {
    const fetchCertificateData = async () => {
      try {
        const response = await axios.get(
          "https://e-learning-backend-seven.vercel.app/get-all-certificates"
        );
        const filteredCertificate = response.data.data.find(
          (certificate) => certificate.certificateID === certificateID  
        );
        setCertificateData(filteredCertificate);
        setIsDataLoaded(true); // Set data loaded flag to true after fetching data
      } catch (error) {
        console.error("Error fetching certificate data:", error);
      }
    };
    fetchCertificateData();
  }, [certificateID]);

  const handleDownload = () => {
    if (!certificateData) {
      console.error('Certificate data not available');
      return;
    }
    exportToPNG('certificate-main', 'certificate');
  };

  const exportToPNG = (elementSelector, fileName) => {
    const elementToCapture = document.getElementById(elementSelector);
    if (!elementToCapture) {
      console.error('Element not found for capture');
      return;
    }
    html2canvas(elementToCapture, { useCORS: true }).then((canvas) => {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  return (
    <div>
      <div className="dowload-btn">
        <ArrowCircleDownIcon
          style={{ color: 'rgb(193 31 31)', height: '5rem', width: '5rem', cursor: 'pointer' }}
          onClick={handleDownload}
          disabled={!isDataLoaded} 
        />
      </div>
      <div id="certificate-main" className="certificate-main">
        <div className="logo-ino">
          <img alt="ec" src={logo} /> 
          <p className="logo-inop">
            Unique Identifier : <span>{certificateData?.certificateID}</span> 
          </p>
        </div>
      
        <div className="certificate-text">
          <h2>Certificate of Completion</h2>
          <p className="p">This certificate is awarded to</p>
          <p className="recipient-name">
            Mr/Mrs. <span>{certificateData?.userName}</span>
          </p>
          <p className="recipient-level">
            for <span>{certificateData?.planName} Level Blockchain</span>
          </p>
          <p className="p-dec">
            Your dedication, passion, and hard work have significantly contributed to the success of project/cause/activity, and we express our heartfelt gratitude.
          </p>
        </div>
        <div className="certificate-image">
          <div className="cer-img">
            <img alt="" src={certificate} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
