import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import "./config/Firebase"
import App from "./App";
// import Demo from './Demo';
// import Certificate from "./Component/CourseDetails/Certificate/Certificate"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
     <App />
     {/* <Demo/> */}
     {/* <Certificate/> */}
  </BrowserRouter>  
  </React.StrictMode>
);
reportWebVitals();


