import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./Crashvideo.css";
import Footer from '../../Footer/Footer';


function Crashvideo() {
  const { crashCourseId } = useParams();
  const [video, setVideo] = useState(null); // Initialize video state to null

  useEffect(() => {
    // Fetch data from your API based on the crashCourseId
    fetch(`https://e-learning-backend-seven.vercel.app/get-videoById/${crashCourseId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setVideo(data.data); // Set the single video object to video state
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [crashCourseId]);
const planId = video?.planId
console.log('planId:', planId);
  return (
   <div>
     <div>
       {video && (
    <div className='crash-container'>
     
        <div className='CrashCurriculum-C' key={video._id}>
          <div className='CrashCurriculum'>
            <Link to={`/CrashCourse/${planId}`}>
             <div className='arrow-forward'>
             <p className='Curriculum'>Crash Curriculum 
             </p>
             <ArrowForwardIosIcon sx={{width:'0.8em',height:'0.8em',color:'#C68A6D',marginLeft:'0.5em','&:hover': {
            color: '#C64709',
        }
        }}/>
             </div>
            </Link>
           
           
            <p className='Hyperledger'>{video.title}</p>
          </div>
          <p className='crash-Blockchain'>{video.description}</p>
        </div>
   
      <div className='crash-Blockchain-image-C'>
        <video src={video.videoUrl} alt='' className='crash-Blockchain-image' controls />
      </div>
    </div>
       )}
    </div>
    <Footer/>
   </div>
  );
}

export default Crashvideo;
