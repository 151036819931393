import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Viewtickets.css";
import image3 from "../../Image/Cancel (2).png"
import image2 from "../../Image/Check (1).png"
import Footer from '../../Footer/Footer';

function Viewtickets() {
  const [tickets, setTickets] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetch('https://e-learning-backend-seven.vercel.app/get-tickets') 
      .then(response => response.json())
      .then(data => {
        const formattedData = data.map(ticketData => ({
          id: ticketData._id,
          date: new Date(ticketData.createdAt).toLocaleDateString(),
          ticketsID: ticketData.ticketId,
          category: ticketData.category,
          status: ticketData.status
        }));
        setTickets(formattedData);
      })
      .catch(error => console.log(error));
  }, []);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const email = localStorage.getItem('email');
  const profileURL = localStorage.getItem('profileURL');

  return (
    <div>
      <div className="table-main02">
      <div className="table-container">
        <div className="table-content">
          <h2 className="previous">Previous tickets</h2>
          <table className="table">
            <thead className="table-head-view">
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Tickets ID</th>
                <th>Category</th>
                <th>Status</th>
                <th>View Chat & Replay</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {tickets.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.date}</td>
                  <td>{item.ticketsID}</td>
                  <td>{item.category}</td>
                  <td>{item.status}</td>
                  <td>
                    <button className="view-replay" onClick={openPopup}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="raise">
            <Link to="/Raiseticket">
              <button className='raise-btn'>Raise Ticket</button>
            </Link>
          </div>
        </div>
      </div>
      
      {isOpen && (
               <div className='raiseup-feed-container'>
               <div className='raiseup-feed-cont'>
                 <div className='cancel-feed-c'>
                   <img src={image3} alt='' className='cancel-feed' onClick={() => setIsOpen(false)}></img>  
                    </div>
              <div className='raiseupfeed-main'>
              <p className='text-raiseup'>Thank you for your valuable tickets!</p>
                <img src={image2} alt='' className='check-feed'></img>
               <div className='raise-popup-requst'>
               <Link to="/" className='a-tag'><button className='raiseup-backhome-btn'>Back to home </button></Link>
               </div>
              </div>
               </div>
              </div>
      )}
    </div>
    <Footer/>
    </div>
  );
}

export default Viewtickets;

