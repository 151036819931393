import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';

const PaginationWrapper = styled('nav')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  paddingBottom: '2rem'
});

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

const ListItem = styled('li')({
  margin: '0 5px',
});

const Button = styled('button')({
  padding: '8px 12px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  background: '#fff',
  cursor: 'pointer',
  fontWeight: 'bold',
  transition: 'background 0.3s',

  '&:hover': {
    background: '#eee',
  },
});

const ActivePageButton = styled(Button)({
  background: 'gray', // Set your desired color for the active page
  color: '#fff', // Set your desired text color for the active page
});

const UsePagination = ({ currentPage, itemsPerPage, totalItems, onPageChange }) => {
  const { items, ...paginationProps } = usePagination({
    count: Math.ceil(totalItems / itemsPerPage),
    page: currentPage,
  });

  return (
    <PaginationWrapper>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = selected ? (
              <ActivePageButton
                type="button"
                {...item}
                onClick={() => onPageChange(page)}
              >
                {page}
              </ActivePageButton>
            ) : (
              <Button
                type="button"
                {...item}
                onClick={() => onPageChange(page)}
              >
                {page}
              </Button>
            );
          } else {
            children = (
              <Button type="button" {...item} onClick={() => onPageChange(page)}>
                {type}
              </Button>
            );
          }

          return <ListItem key={index}>{children}</ListItem>;
        })}
      </List>
    </PaginationWrapper>
  );
};

export default UsePagination;
