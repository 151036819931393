import React, { useState, useEffect } from 'react';
import "./Crashvideo.css";
import img1 from "../../Image/_.png";
import { Link, useParams } from 'react-router-dom';
import Footer from '../../Footer/Footer';


function Videos() {
  const { _id} = useParams();
  const [videoData, setVideoData] = useState(null); // State to store the fetched video data

  useEffect(() => {
    fetch(`https://e-learning-backend-seven.vercel.app/get-videoById/${_id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setVideoData(data.data);
      })
      .catch(error => {
     
        console.error("Error fetching data from API:", error);
      });
  }, [_id]);

  const level = videoData?.level 
  const planId = videoData?.planId
  return (
   <div>
     <div className='crash-container'>
      {videoData && (
        <div className='CrashCurriculum-Cont'>
          <div className='CrashCurriculum'>
            <Link to="/Trinity"> <p className='Curriculum'>Passage</p></Link>
            <img src={img1} alt='' width={10} height={15}></img>
            <Link to={`/PlansDetails/${planId}`}><p className='Curriculum'>{level} Passage</p></Link>
            <img src={img1} alt='' width={10} height={15}></img>
            <p className='Hyperledger'>Video 1</p>
            
          </div>
          <p className='crash-Blockchain'>{videoData.description}</p>
          <div className='crash-Blockchain-image-C'>
        
          
            <video src={videoData.videoUrl} alt='' className='crash-Blockchain-image' controls></video>
            
          </div>
        </div>
      )}
    </div>
    <Footer/>
   </div>
  );
}

export default Videos;
