import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./Raiseticket.css";
const ITEM_HEIGHT = 20;

export default function LongMenu({ ticketId, handleToggleDrawer, user }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleToggleDraw = () => {
    setDrawerOpen(!drawerOpen);
  };
  const formatDate = (dateString) => {
    try {
      if (!dateString) {
        throw new Error("Date string is empty");
      }
      const date = new Date(dateString);

      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return "Invalid Date";
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleViewClick = () => {
    handleCloseMenu();
    handleToggleDraw();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const list = (
    <Box
      sx={{ width: 680 }}
      role="presentation"
      onClick={(event) => {
        event.stopPropagation();
      }}
      onKeyDown={handleCloseDrawer}
    >
      {user && (
        <div className="sideber-main">
          <div className="Ticket-details">
            <h3>TicketId </h3>
            <span>ID : {user.ticketId}</span>
          </div>
          <div className="ticket-user-details">
            <div className="user-name">
              <p>Email</p>
              <span>{user.email}</span>
            </div>
            <div className="ticket-category-01">
              <p>Category</p> <span>{user.category}</span>
            </div>
            <div className="ticket-date">
              <p> Date </p> <span>{formatDate(user.createdAt)}</span>
            </div>
            <div className="ticket-status-01">
              <p>Status </p> <span>{user.status}</span>
            </div>
            <div className="ticket-priority">
              <p>Priority</p>
              <span>{user.priority}</span>
            </div>
          </div>

          <div className="Query-Description">
            <p>Query Description</p>
            <div className="textarea">{user.reason} </div>
          </div>
          <div className="attached-file">
            <img
              src={user.screenShotURL}
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "10px",
                objectFit: "fill",
              }}
              alt="screenshot"
            />
          </div>

          <div className="button-container">
            <button className="save-btn-01">Save</button>
        
          </div>
        </div>
      )}
    </Box>
  );

  return (
    <div>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={anchorEl ? "long-menu" : undefined}
          aria-expanded={Boolean(anchorEl)}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              height: ITEM_HEIGHT * 2.6,
              width: "15ch",
            },
          }}
        >
          <MenuItem onClick={handleViewClick}>
            <div onClick={() => handleToggleDrawer(ticketId)}>view</div>
          </MenuItem>
        </Menu>

        <SwipeableDrawer
          anchor="right"
          open={drawerOpen}
          onClose={handleCloseDrawer}
          onOpen={handleToggleDraw}
        >
          {list}
        </SwipeableDrawer>
      </div>
      <div></div>
    </div>
  );
}
