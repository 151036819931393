import React from 'react'
import useScrollToTop from '../../../Hooks/useScrollToTop';
import Footer from '../../../Footer/Footer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
     <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 

     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>E-LEARNING POLICY</h1>
    <h5>NATIONAL EDUCATION POLICY 2020:</h5>
    <p> In July 2020, New Education Policy 2020 was introduced by the Ministry of 
Education with its vision to transform and digitalize the country’s education system. It 
was aimed not only to digitalize but also to universalize the pedagogy from pre to 
secondary school and has a particular focus on E-Learning. During the pandemic, the 
world and so did India realize the required shift to E-Learning when schools and 
colleges are forced to shut down and operate online.</p>
<p>As well said, that ‘Necessity is the mother of invention, the call of E-Learning during 
a hard time of Covid-19 gave an explosive pace of technological development to the 
educational institution and allied them with the sheer optimization of tech-savvy 
educators and students.</p>
<p> Artificial Intelligence, Machine Learning, Blockchains, Smart Boards, Handheld 
computing devices, and Adaptive computer testing for student development have not 
only changed the process of learning but also gone beyond all extensive research.</p>
<p>With the Introduction of the New National Education Policy 2020, online education 
in India flourished and was promoted all over the country with a variety of educational 
software and portal for student and teacher development at all levels. Major of these 
platforms offered study material in all Indian language with a wide range of 
accessibility for the remote area and Divyang students. NCERT, CIET, CBSE, and NIOS 
played a vital role in not only developing but also uploading the study materials, 
content, and latest updates on the respective portal like DIKSHA.</p>
<h5>KEY INITIATIVE UNDER NEP 2020 FOR E-LEARNING</h5>
<h5 style={{fontSize: "1.3rem"}}>Digital Infrastructure:</h5>
<p>New Education Policy 2020 urged the need to create an open, transformed, and 
evolve E-Learning digital infrastructure to solve the lack of reach and complex 
diversity in the education system by the centre and state education bodies. It also 
targets the technological teaching, learning and evaluation process, teacher 
preparation, and professional development with enhancing educational access and 
planning.
</p>
<h5 style={{fontSize: "1.3rem"}}>Pilot Studies for Online Education:</h5>
<p>NETF, CIET, NIOS, IGNOU, IITs, and NITs, were made responsible to conduct pilot 
studies and evaluate the integrated education with the online education with the 
annual assessment and formats of E-Content for the communicated and continuous 
improvement.</p>
<h5 style={{fontSize: "1.3rem"}}>Digital Divide:</h5>
<p>The digital accessibility with high internet connection is very limited in remote 
areas even today. There the mass media like television and radio plays an important 
role through their educational programs which cater to the student's population of 
that area. The expansion E-Learning with the NEP 2020 resulted in the broadening of 
online education to remote areas by installing appropriate network connections there 
as well as removing the digital divide.</p>
<h5 style={{fontSize: "1.3rem"}}>Online Assessment and Exam:</h5>
<p>National Assessment Center PARAKH, School Boards, and NTA are responsible to 
implements the assessments framework with the encompassed design of 
competencies, analytics, and standardized assessments.</p>
<p> Digitalization and Education have the potential to orchestrate the pedagogy 
transformation in India. All with the NEP 2020, the E-Learning wagon of the 
Education sector has Digital Edu-Infra plan under NEP has the efficient potential to 
complete the rapidly evolving transformation in educational and technology around 
the globe.</p>





  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
  
   <Footer/>
    </div>
    </div>
  )
}

export default PrivacyPolicy