import React,{useEffect,useState} from 'react'
import {Box} from '@mui/material';
import './ProfileOverview.css'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Footer from '../../../Footer/Footer';



function ProfileOverview() {
  const [responseData,setResponseData] =useState([])
 

useEffect (()=>{
  const FetchingData =async ()=>{
     try{
        const response =await axios.get("https://e-learning-backend-seven.vercel.app/get-all-videos");
        setResponseData(response.data)
        
     }catch{
       console.log('somthing error')
     }
  }
  FetchingData()
},[])

const filteredVideos =responseData?.data?.filter(item=>item.level === 'Crash Course')

const [reward, setReward] = useState([]);

useEffect(() => {
  const fetchRewardPoints = async () => {
    try {
      const email = localStorage.getItem("email");
     
      const response = await axios.get(`https://e-learning-backend-seven.vercel.app/get-reward-points/${email}`);
      setReward(response.data);
    } catch (error) {
      console.error('Error fetching reward points:', error);
    }
  };

  fetchRewardPoints();
}, []);

console.log(reward, "reward points");

  return (
    <div>
       <div className='view-item'>
    <div className='view-item1'>
    <div className='view-itemLeft'>
     <div className='resumevideo-top'>
      <p>Recently Played Video</p>
     </div> 
     <div className='resumeVideo-card'>
      <div className='card2'>
       <img src="https://eclature.com/wp-content/uploads/2019/03/blockchain-technology-services.jpg" alt='' />
       <div className='card-sec-begginer'><button className='btn-begginer'>Begginer</button></div>
       <span>Blockchain and the future depend on blockchain</span>
       <div className='card-sec-begginer'>
     
       <div className='card-duration'>
        <p>Durations :</p>
        <span> 1hr : 30mins</span>
       </div>
       </div>
      </div>
      <div className='resumeVideo-detail'>
        <h1>Blockchain and web 3 futures</h1>
        <p>Type : Crash course</p>
        <div className='resumeVideo-button'>Watch Now</div>
      </div>
     </div>
    
    </div>
    <div className='view-itemRight'>
      <div className='itemRight-top'>
     <p> Rewards points</p>
      </div>
      <div className='itemRight-btm'>
      <p>{reward.data}</p>
      <span>Equals to {reward.data}INR</span>
      </div>
    </div>
   </div>
    <div className='crashcourse-total'>
 <div className="My-course-btm" >
<div className='My-course-bto'>
<div className='My-course-bto-11'>
<div className='My-course-bto-h1'>
Crash Courses
</div>
<div className='My-course-bto-p'>
{filteredVideos?.length}
</div>
</div>
<div>
</div>
</div>
<div className='filterd'>
{filteredVideos?.map((videos,index)=>( 
      <div className='card1' key={videos._id}>
      <img src="https://www.sqorus.com/wp-content/uploads/2022/12/Article3_metaverse_e-learning-1-e1670853234868.png" alt='videoThumb' /> 
       <div className='card-sec-begginer'><button className='btn-begginer'>Begginer</button></div>
       <span>{videos.description.substring(0,55)}</span>
       <div className='card-sec-begginer'>
     
       <div className='card-duration'>
        <p>Durations :</p>
        <span> 1hr : 30mins</span>
       </div>
       </div>
      </div>
        ))}
       
 </div>
 <Link to='/CrashCourse/6489b8652931b70b2b9d58fb'><div className='ViewAll-profile-btn'><button className='profile-btn-viewall'>View All</button></div></Link>
</div>

    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default ProfileOverview