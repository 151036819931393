
import React, { useState, useEffect, useRef } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';

 

const LottieFile = () => {

const [autoplay, setAutoplay] = useState(false);

const playerRef = useRef();

 

useEffect(() => {

// Delay the autoplay by 2 seconds

const delayTimeout = setTimeout(() => {

setAutoplay(true);

}, 6000);

 

// Cleanup the timer on unmount

return () => clearTimeout(delayTimeout);

}, []);

 

useEffect(() => {

if (autoplay && playerRef.current) {

playerRef.current.play();

}

}, [autoplay]);

 

return (

<Player

ref={playerRef}

autoplay={autoplay}

loop={false}

keepLastFrame={true}

src="https://lottie.host/75bf5f2f-f952-47f7-a9a1-cb3b288974d2/jj1VmK4xPI.json"

className='Player'

/>

);

};

 

export default LottieFile;