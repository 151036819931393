import React from 'react'
import useScrollToTop from '../../Hooks/useScrollToTop';
import Footer from '../../Footer/Footer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

function Refund() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
     <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins',fontVariant:'all-petite-caps'}}>Refund Policy
</h1>
  
   <p> This Refund Policy ("Policy") applies to any subscriptions purchased for INOCYX 
Learning platform ("Subscription") by paying a subscription fee ("Subscription Fee") 
on <a href='https://www.inocyxlearning.com/' style={{textDecoration: "underline"}}> www.inocyxlearning.com </a> ("Platform") made available by INOCYX Technologies 
Private Limited ("Company"). </p>
<p>This Policy governs the terms and conditions for the refund of the Subscription Fee 
paid by a user or learner on the Platform towards the Subscription ("Learner").</p>

<p> Please note that this Policy is specific to the subscription for courses offered by 
INOCYX Learning platform and does not apply to any other subscriptions, products, 
or features on the Platform.</p>
<p>This Policy should be read along with the Terms and Conditions and Privacy Policy 
available on the Platform ("Platform Terms"). Any term not specifically defined herein 
shall have the meaning ascribed to it in the Platform Terms.</p>
<h5>Refund Process:</h5>
<p> If the Subscription does not meet the learner's expectations or requirements due to 
any circumstances or limitations from the Company's side, the learner may request a 
refund of the Subscription Fee by writing to the Company ("Refund Request"). The 
Refund Request should fall within the "Refund Window(s)" provided below, and upon 
acceptance by the Company, the transaction stands cancelled, and the Subscription 
Fee shall be refunded to the Learner, subject to any applicable deductions.</p>

<h5>Refund Windows:</h5>
<ul>
    <li>
        <p> 85% of the Subscription Fee paid if the Refund Request is raised within before  7 days 
from the start of the course.
      </p>
</li>
    <li><p>70% of the Subscription Fee paid if the Refund Request is raised within before 15 days 
from the start of the course.
</p></li>
<li>
    <p> No refund 0% of the Subscription Fee paid if the Refund Request is raised after 15 days 
from the start of the course.
</p>
</li>
</ul>
<h5>Refund Exclusions:</h5>
<p> The Company shall not be liable to provide any refunds for reasons including lack of 
usage, dissatisfaction with the course/educators, for any Refund Request received after 
the expiry of the Refund Window. The Company has the sole discretion to decide on 
refunds for requests received after the Refund Window </p>
<h5>Refund Verification:</h5>
<p>To process the refund, the Company may contact the Learner to verify details within 7 
(seven) business days from the date of the refund request. Refund Requests must be 
raised from the registered email id and phone number of the Learner. If the Learner 
wishes to continue with the Subscription after initiating a Refund Request, it cannot 
be withdrawn. The user must be subscribe again</p>
<h5>Refund Method:</h5>
<p>The Learner shall receive the refund of the Subscription Fee in the same payment 
source used for the initial payment. 
 </p>
 <h5>Deductions:</h5>
<p> While the Company refunds the Subscription Fee, there shall be deductions for 
interest and taxes, as applicable. The Company reserves the right to withdraw or 
cancel the refund in case of identified fraudulent activity attributable to the Learner, 
including violation of Platform Terms and/or applicable laws</p>
  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
     <Footer/>
    </div>
    </div>
  )
}

export default Refund