import React from 'react'
import imag from '../Image/Frame1.png';
import "./Footer.css"
import facebook from "../Image/Vector (11).png"
import insta from "../Image/Vector (12).png"
import twitter from "../Image/Vector (13).png"
import telegram from "../Image/Vector (14).png"
import logofooter from "../Image/Frame 5616 (1).png"
import { Link } from 'react-router-dom';
function Footer() {
  return (
      <div className='Footer-container'>
        <div className='Footer-logo-terms'>
         <div className='footer-text-contai'>
       <Link to="/"><img className='Footer-logo' src={logofooter} alt='logo'/></Link>  
       <div className='footer-copyright-cont'>
       <p className='footer-logo-text'>© 2023 INOCYX Learning. All Rights Reserved.</p>
         <p className='footer-logo-text'> Originated by INOCYX Tech Team.</p>
       </div>
       <div className='footer-img'>
              <a href='https://twitter.com/INOCYX' target='blank'><img src={twitter} alt='twitter' className='twitter'></img></a>
              <a href='https://www.instagram.com/inocyx' target='blank'><img src={insta} alt='insta' className='twitter'></img></a>
              <a href='https://www.facebook.com/people/Inocyx/100083436154588/' target='blank'><img src={facebook} alt='facebook' className='twitter'></img></a>
            
            </div>
         </div>
         <div className='termscondition-cnt'>
            <div className='terms-condition-main'>
            <div className='terms-condition-1'>
                <p className='terms-condition'>COMPANY</p>
                <Link to= "/Term&Condition"><p className='terms-condition-text'>Terms & Conditions</p></Link>
                <Link to= "/Guidelines"><p className='terms-condition-text'>Guidelines</p></Link>
                <Link to= "/Disclaimer"><p className='terms-condition-text'>Disclaimer</p></Link>
                <Link to="/Feedbackhelp" className='a-tag'><p className='terms-condition-text'>Feedback</p></Link>
                <Link to="/gethelp" className='a-tag'> <p className='terms-condition-text'>Get Help</p></Link>
                <Link to="/contactus" className='a-tag'><p className='terms-condition-text'>Contact Us</p></Link>
            </div>
            <div className='terms-condition-1'>
                <p className='terms-condition'>POLICIES</p>
              <Link to="/PrivacyPolicy"><p className='terms-condition-text'>Privacy Policy</p></Link>     
              <Link to= "/PaymentPolicy"><p className='terms-condition-text'>P&T,Conditions Policy </p></Link>     
              <Link to= "/ReportPolicy"><p className='terms-condition-text'>Report Policy</p></Link>     
              <Link to= "/LearningPolicy"><p className='terms-condition-text'>E-Learning Policy</p></Link>  
              <Link to= "/StudentPolicy"><p className='terms-condition-text'>Student Privacy </p></Link> 
              <Link to= "/TechnicalPolicy"> <p className='terms-condition-text'>Email & Discussion Policy</p></Link> 
              <Link to= "/TechnicalPolicy"> <p className='terms-condition-text'> </p></Link> 
              <Link to="/Intellectual"><p className='terms-condition-text'>Intellectual Right Policy</p></Link> 
              <Link to="/Refund"><p className='terms-condition-text'>Refund Policy</p></Link> 
              <Link to="/Reward"><p className='terms-condition-text'>Reward Policies</p></Link> 
            </div>
              </div>
            </div>
         </div>
      </div>
  )
}

export default Footer
