import React from 'react'
import useScrollToTop from '../../../Hooks/useScrollToTop';
import Footer from '../../../Footer/Footer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
  <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>DISCLAIMER</h1>
    <p>Our Disclaimer was last updated in January 2023.</p>
   <h5 style={{fontSize: "25px"}}>Interpretation and Definitions</h5>
   <h5>Interpretation</h5>
   <p>The words of which the initial letter is capitalized have meanings defined under the 
following conditions. The following definitions shall have the same meaning 
regardless of whether they appear in singular or in plural. </p>
   <h5 style={{fontSize: "25px"}}>Definitions</h5>
<p>For the purposes of this Disclaimer:</p>
<ul>
    <li><p> "Company" (referred to as either "INOCYX", "We", "Us" or "Our" in this Disclaimer) 
refers to [INOCYX Private Limited Company] </p></li>
    <li><p> "Service" refers to the Website</p></li>
    <li><p> "You" means the individual accessing the Service, or the company, or other legal 
entity on behalf of which such individual is accessing or using the Service, as 
applicable.</p></li>
    <li><p>"Website" refers to INOCYX LEARNING, accessible from<a href='https://www.inocyxlearning.com/' style={{textDecoration: "underline"}}>www.inocyxlearning.com</a></p></li>
</ul>
<h5>Disclaimer</h5>
<p>The information contained on the Service is for general information purposes only. 
The Company assumes no responsibility for errors or omissions in the contents of the 
Service. In no event shall the Company be liable for any special, direct, indirect, 
consequential, or incidental damages or any damages whatsoever, whether in an 
action of contract, negligence or other tort, arising out of or in connection with the 
use of the Service or the contents of the Service.</p>
<p>The Company reserves the right to make additions, deletions, or modifications to the 
contents on the Service at any time without prior notice. The Company does not 
warrant that the Service is free of viruses or other harmful components. </p>
<h5>Fitness & Nutritional Information Disclaimer</h5>
<p>The Service can offer health, fitness and nutritional information including, without 
limitation, advice and recommendation, that is provided solely as general education 
and informational purposes.</p>
<p>Use of the advice and information contained herein is at your sole choice and risk. 
You should not rely on this information as a substitute for, nor does it replace, 
professional medical advice, diagnosis, or treatment. Always consult your physician or 
healthcare provider before beginning any nutrition or exercise program</p>
<p>If You choose to use this information without prior consent of your physician, you are 
agreeing to accept full responsibility for your decisions and agreeing to hold harmless 
the Company.</p>
<h5>FTC Affiliate Disclaimer</h5>
<p>The disclosure that follows is intended to fully comply with the Federal Trade 
Commission's policy of the United States that requires the Company to be transparent 
about all affiliate relations the Company may have on the Service.</p>
<p>You should assume that some of the links are "affiliate links", a link with a special 
tracking code. This means that if You click on an affiliate link and purchase the item, 
the Company may receive an affiliate commission.</p>
<p>This is a legitimate way to monetize and pay for the operation of the Service and the 
Company gladly reveal its affiliate relationships to You.</p>
<p>This is a legitimate way to monetize and pay for the operation of the Service and the 
Company gladly reveal its affiliate relationships to You.</p>
<h5>External Links Disclaimer</h5>
<p>The Service may contain links to external websites that are not provided or 
maintained by or in any way affiliated with the Company.</p>
<p>Please note that the Company does not guarantee the accuracy, relevance, timeliness, 
or completeness of any information on these external websites. </p>
<h5>Fair Use Disclaimer</h5>
<p>The Company may use copyrighted material which has not always been specifically 
authorized by the copyright owner. The Company is making such material available 
for criticism, comment, news reporting, teaching, scholarship, or research</p>
<p>The Company believes this constitutes a "fair use" of any such copyrighted material as 
provided for in section 107 of the United States Copyright law.
</p>
<p>If You wish to use copyrighted material from the Service for your own purposes that 
go beyond fair use, you must obtain permission from the copyright owner.
</p>
<h5>Views Expressed Disclaimer </h5>
<p>The Service may contain views and opinions which are those of the authors and do 
not necessarily reflect the official policy or position of any other author, agency, 
organization, employer or company, including the Company.</p>
<p>Comments published by users are their sole responsibility and the users will take full 
responsibility, liability and blame for any libel or litigation that results from something 
written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserves the right to delete any 
comment for any reason whatsoever.
</p>
<h5>No Responsibility Disclaimer</h5>
<p>The information on the Service is provided with the understanding that the Company 
is not herein engaged in rendering legal, accounting, tax, or other professional advice 
and services. As such, it should not be used as a substitute for consultation with 
professional accounting, tax, legal or other competent advisers.</p>
<p>In no event shall the Company or its suppliers be liable for any special, incidental, 
indirect, or consequential damages whatsoever arising out of or in connection with 
your access or use or inability to access or use the Service.</p>
<h5>"Use at Your Own Risk" Disclaimer</h5>
<p>All information in the Service is provided "as is", with no guarantee of completeness, 
accuracy, timeliness or of the results obtained from the use of this information, and 
without warranty of any kind, express or implied, including, but not limited to 
warranties of performance, merchantability and fitness for a particular purpose.</p>
<p>The Company will not be liable to You or anyone else for any decision made or action 
taken in reliance on the information given by the Service or for any consequential, 
special or similar damages, even if advised of the possibility of such damages.</p>
<p>Contact Us If you have any questions about this Disclaimer, you can contact Us:
</p>
<ul>
    <li><p>By visiting this page on our website:<a href='https://www.inocyxlearning.com/' style={{textDecoration: "underline"}}>www.inocyxlearning.com</a> </p></li>
    <li><p>By sending us an email:<a href='support@inocyxlearning.com' style={{textDecoration: "underline"}}>support@inocyxlearning.com</a></p></li>
</ul> 
  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
   <Footer/>
    </div>
    </div>
  )
}

export default PrivacyPolicy