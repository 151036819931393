const plans = [
 {
    title : "From Fundamentals to Applications",
    description : "This intermediate-level course provides a comprehensive exploration of blockchain technology, covering both theoretical concepts and practical applications. Participants will delve into the inner workings of blockchain, gaining a deep understanding of its decentralized nature, cryptographic principles, and consensus mechanisms." ,
    name : "Intermediate" ,
    price : "2000"
 },
 {
    title : " Basic of Blockchain",
    description : " Blockchain technology is a decentralized digital ledger system that records transactions across a network of computers in a secure, transparent, and immutable manner. At its core, a blockchain consists of a series of blocks, each containing a list of transactions cryptographically linked to the previous block, forming a chain. The decentralized nature of blockchain eliminates the need for intermediaries, such as banks or government agencies, to verify and authenticate transactions, thereby reducing costs and enhancing efficiency. " ,
    name : "Beginner" ,
    price : "Free"
 },
 {
    title : "From Basics to Advanced Applications",
    description : " This expert-level course is designed to provide comprehensive knowledge and hands-on experience in blockchain technology. From understanding the fundamentals to exploring advanced applications, this course covers every aspect of blockchain technology in depth. " ,
    name : "Expert" ,
    price : "3000"
 },
 {
    title : "A Crash Course in Revolutionary Technology",
    description : " Unlock the potential of blockchain technology with this crash course designed to provide a comprehensive understanding in no time. Dive into the fundamentals of blockchain, exploring its decentralized nature, cryptographic principles, and its transformative impact across industries. Learn about the mechanics behind blockchain, including consensus mechanisms like Proof of Work and Proof of Stake, smart contracts, and distributed ledger technology." ,
    name : "Crash Course"
 }
  
] 

 export default plans