import React from 'react'
import "../Privacy&Policy/Private&Policy.css"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
// import Nav from '../../LandingComponent/Home/NavItem/NavItem';
import useScrollToTop from '../../../Hooks/useScrollToTop';

import Footer from '../../../Footer/Footer';

function PrivacyPolicy() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
  <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>INTELLECTUAL PROPERTY RIGHTS POLICY</h1>

   <p>  The Internet makes it very easy to obtain and use work created by others, hence 
easy to violate their rights to this work. An intellectual property rights policy helps 
students understand and avoid the improper use of the intellectual property of others. 
Below INOCYX learning briefly discuss copyright law, fair use guidelines, and the 
Digital Millennium Copyright Act (DMCA) and the Technology, Education, and 
Copyright Harmonization (TEACH) Act—subjects not infrequently misunderstood by 
students and sometimes by e-learning instructors.  </p>

<p>The information INOCYX learning present has been abstracted from the e-learning 
literature and related sources. Bear in mind that what follows was written by laymen 
and that INOCYX learning have not consulted lawyers to verify the validity of the 
ideas INOCYX learning present.
</p>

<h5>Copyright Law</h5>
<p> Copyright is the legal exclusive right of someone who creates a work—for example 
a poem, a song, a photograph, an article, a book, or a video—to control the copying of 
all or a portion of that work. By providing a basis for lawsuits to compensate for 
financial or emotional loss resulting when someone uses another’s work unfairly, 
copyright law attempts to assure that INOCYX learning can profit fairly from our 
efforts. Someone who creates a work possesses what is sometimes called intellectual 
property rights to that artifact. No specific action is required to obtain these rights, 
nor must a copyright notice be attached to the work for it to be protected. Copyright is 
implied by the existence of the work as soon as the work is placed in a medium that 
can be distributed or copied, even when the medium is simply an unpublished 
manuscript.
</p>
<p> Copyrights are not eternal. When an artifact becomes old enough to be out of 
copyright, others may use it as they wish. Such material is said to be in the public 
domain. For example, editions of Mark Twain’s novels published in the nineteenth and 
early twentieth centuries are long out of copyright, hence in the public domain. You 
and your students are free to quote from any of these old editions or even to copy such 
a book in its entirety to a Web site. Except under the fair use guidelines explained 
below, however, you could not post pages scanned from a contemporary reprint of one 
of Twain’s novels. This remains true even though the copyrighted reprint was itself 
produced from an edition of the novel in the public domain.
</p>
<p> Many Web authors, but certainly not all, consider their Web sites to be in the 
public domain and are willing to share freely the information these sites contain. To 
be on the safe side, however, one should assume that material created by others—
electronic or otherwise—is not available free of charge. This includes everything 
obtained from the Web—text, images, audio, or video—as well as anything you use or 
scan from a picture, book, magazine, or other source. This doesn’t mean that you and your students can’t use materials from the Web and other sources to create e-learning 
assignments and resources; it simply means that when you use such materials, you 
must follow the guidelines explained below. a copyrighted work (or a portion thereof) 
to a legitimate student audience. Compliance with the act is likely—but of course not 
ensured, since compliance is a legal issue—when instructors and/or institutions: </p>
<ul>
    <li><p> Limit access to the copyrighted works to students currently enrolled in the 
class</p></li>
    <li><p> Limit access to only the time needed to complete the class session or course.</p></li>
    <li><p> Inform instructors, students, and staff of copyright laws and policies.</p></li>
    <li><p> Prevent further copying or redistribution of copyrighted works</p></li>
    <li><p> Do not interfere with copy protection mechanisms.</p></li>
</ul>
<h5>Concluding Remarks</h5>
<p>Several of the policies discussed here might best be formulated at the institutional 
level rather than at the level of individual courses. Notable among these are a student 
protection privacy policy, a discussion policy, and an intellectual property rights 
policy. formulating e-learning policies is a task well worth undertaking</p>
<p>Students are more likely to achieve the learning goals you help them set when they 
know precisely what rules govern the pursuit of these goals. Moreover, the use of elearning policies will save you time in the long run by simplifying course 
administration.</p>
<p> They provide readily available answers to the numerous procedural questions that 
inevitably arise as students' progress through your course, questions that—absent 
your e-learning policy statements—you would have to answer individually many times 
over.</p>
  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
  
    <Footer/>
    </div>
    </div>
  )
}

export default PrivacyPolicy