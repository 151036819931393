import React, { useRef, useState } from 'react';
import 'swiper/css';
import CheckIcon from '@mui/icons-material/Check';
import './Premium.css';
import { Link } from 'react-router-dom';

function Premium() {
  const swiperRef = useRef(null);
  const [currentCard, setCurrentCard] = useState(0);

  const rotateRight = () => {
    setCurrentCard((prevCard) => (prevCard + 1) % 3);
  };

  const rotateLeft = () => {
    setCurrentCard((prevCard) => (prevCard - 1 + 3) % 3);
  };

  const handleCardClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      const currentIndex = swiper.activeIndex;
      const totalSlides = swiper.slides.length;
      const nextIndex = currentIndex < totalSlides - 1 ? currentIndex + 1 : 0;
      swiper.slideTo(nextIndex);
    }
  };

  return (
    <div id='PLANS'>
    <div className='gradient'>
      <div className='backgraound-gradient'>
        <div className='premium'>
          <div className='premium-title'>
            <p>ELEVATE TO PREMIUM</p>
          </div>
          
        </div>
        <div className='premium-cards'>
          <div className='premium-card-1'>
            <div className='pre-card-start'>
              <p>Beginner</p>
            </div>
            <div className='pre-card-price'>
              <p>Free</p>
              <p className='inr'>INR</p>
            </div>
            <Link to='/PlansDetails/6489b5b48724a7fc3aab1492' style={{ textDecoration: 'none' }}>
              <div className='get-start'>
                <p>UNLOCK</p>
              </div>
            </Link>
            <div className='pre-card-courses'>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>BLOCKCHAIN BASICS MADE EASY</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>CRYPTO FUNDAMENTALS FOR EASY</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>NFT DEMYSTIFIED FOR STARTER</p>
              </div>
            </div>
            <div className='course-details'>
              <div className='course-num'>10 VIDEOS</div>
              <div className='course-w'>|</div>
              <div className='course-t'> 3 TEST</div>
              <div className='course-w'>|</div>
              <div className='course-t'> REWARDS</div>
            </div>
          </div>
          <div className='premium-card-2'>
            <div className='pre-card-pro'>
              <p>Intermediate</p>
            </div>
            <div className='pro-card-price'>
              <p>2000</p>
              <p className='inr'>INR</p>
            </div>
            <Link to='/PlansDetails/6489b7cd8724a7fc3aab1495' style={{ textDecoration: 'none' }}>
              <div className='get-pro'>
                <p>UNLOCK</p>
              </div>
            </Link>
            <div className='pro-card-courses'>
              <div className='course-2'>
                <CheckIcon sx={{ width: '12px', height: '12px', color: 'white' }} />
                <p>ADVANCED CONCEPTS OF BLOCKCHAIN</p>
              </div>
              <div className='course-2'>
                <CheckIcon sx={{ width: '12px', height: '12px', color: 'white' }} />
                <p>NAVIGATING THE CRYPTO LANDSCAPE</p>
              </div>
              <div className='course-2'>
                <CheckIcon sx={{ width: '12px', height: '12px', color: 'white' }} />
                <p>BLOCKCHAIN APP AND USE CASES</p>
              </div>
              <div className='course-2'>
                <CheckIcon sx={{ width: '12px', height: '12px', color: 'white' }} />
                <p>CRYPTOCURRENCY TRADING STRATEGIES</p>
              </div>
            </div>
            <div className='course-details'>
              <div className='course-n'>10 VIDEOS</div>
              <div className='course-wa'>|</div>
              <div className='course-te'>5 TEST</div>
              <div className='course-wa'>|</div>
              <div className='course-te'>REWARDS</div>
            </div>
          </div>
          <div className='premium-card-3'>
            <div className='pre-card-start'>
              <p>Advance</p>
            </div>
            <div className='pre-card-price'>
              <p>3000</p>
              <p className='inr'>INR</p>
            </div>
            <Link to='/PlansDetails/6489b7df8724a7fc3aab1498' style={{ textDecoration: 'none' }}>
              <div className='get-start'>
                <p>UNLOCK</p>
              </div>
            </Link>
            <div className='pro-card-courses'>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>COMPLEX BLOCKCHAIN ARCHITECTURES</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>CRYPTO MARKET STRATEGIES</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>CRYPTO ANALYSIS AND FORECASTING</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>BLOCKCHAIN INNOVATOR AND IMPLEMENTER</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>PRO: ADVANCED CONCEPTS</p>
              </div>
            </div>
            <div className='course-details'>
              <div className='course-num'>10 VIDEOS</div>
              <div className='course-w'>|</div>
              <div className='course-t'>3 TEST</div>
              <div className='course-w'>|</div>
              <div className='course-t'>REWARDS</div>
            </div>
          </div>
        </div>
      </div>

      <div className='icon-cards-container'>
        <figure className='icon-cards mt-3'>
          <div className='icon-cards__content' style={{ '--card-position': currentCard }}>
            <div
              className={`icon-cards__item d-flex align-items-center justify-content-center ${
                currentCard === 0 ? 'active activeCard' : 'inactive'
              }`}
            >
              <span className='span-container'>
                <div className='card-1'>
                <div className='pre-card-start'>
              <p>INITIATE</p>
            </div>
            <div className='pre-card-price'>
              <p>2000</p>
              <p className='inr'>INR</p>
            </div>
            <Link to='Getstart' style={{ textDecoration: 'none' }}>
              <div className='get-start'>
                <p>UNLOCK</p>
              </div>
            </Link>
            <div className='pre-card-courses'>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>BLOCKCHAIN BASICS MADE EASY</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>CRYPTO FUNDAMENTALS FOR EASY</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>NFT DEMYSTIFIED FOR STARTER</p>
              </div>
            </div>
            <div className='course-details'>
              <div className='course-num'>10 VIDEOS</div>
              <div className='course-w'>|</div>
              <div className='course-t'> 3 TEST</div>
              <div className='course-w'>|</div>
              <div className='course-t'> REWARDS</div>
            </div>
                </div>
              </span>
            </div>
            <div
              className={`icon-cards__item d-flex align-items-center justify-content-center ${
                currentCard === 1 ? 'active activeCard' : 'inactive'
              }`}
            >
              <span className='span-container'>
                <div className='card-2'>
                <div className='pre-card-pro'>
              <p>PROGRESSIVE</p>
            </div>
            <div className='pro-card-price'>
              <p>3000</p>
              <p className='inr'>INR</p>
            </div>
            <Link to='Getstart' style={{ textDecoration: 'none' }}>
              <div className='get-pro'>
                <p>UNLOCK</p>
              </div>
            </Link>
            <div className='pro-card-courses'>
              <div className='course-2'>
                <CheckIcon sx={{ width: '12px', height: '12px', color: 'white' }} />
                <p>ADVANCED CONCEPTS OF BLOCKCHAIN</p>
              </div>
              <div className='course-2'>
                <CheckIcon sx={{ width: '12px', height: '12px', color: 'white' }} />
                <p>NAVIGATING THE CRYPTO LANDSCAPE</p>
              </div>
              <div className='course-2'>
                <CheckIcon sx={{ width: '12px', height: '12px', color: 'white' }} />
                <p>BLOCKCHAIN APP AND USE CASES</p>
              </div>
              <div className='course-2'>
                <CheckIcon sx={{ width: '12px', height: '12px', color: 'white' }} />
                <p>CRYPTOCURRENCY TRADING STRATEGIES</p>
              </div>
            </div>
            <div className='course-details'>
              <div className='course-n'>10 VIDEOS</div>
              <div className='course-wa'>|</div>
              <div className='course-te'>5 TEST</div>
              <div className='course-wa'>|</div>
              <div className='course-te'>REWARDS</div>
            </div>
                </div>
              </span>
            </div>
            <div
              className={`icon-cards__item d-flex align-items-center justify-content-center ${
                currentCard === 2 ? 'active activeCard' : 'inactive'
              }`}
            >
              <span className='span-container'>
                <div className='card-3'>
                 <div className='pre-card-start'>
              <p>PROFICIENT</p>
            </div>
            <div className='pre-card-price'>
              <p>4000</p>
              <p className='inr'>INR</p>
            </div>
            <Link to='Getstart' style={{ textDecoration: 'none' }}>
              <div className='get-start'>
                <p>UNLOCK</p>
              </div>
            </Link>
            <div className='pro-card-courses'>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>COMPLEX BLOCKCHAIN ARCHITECTURES</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>CRYPTO MARKET STRATEGIES</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>CRYPTO ANALYSIS AND FORECASTING</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>BLOCKCHAIN INNOVATOR AND IMPLEMENTER</p>
              </div>
              <div className='course-1'>
                <CheckIcon sx={{ width: '12px', height: '12px' }} />
                <p>PRO: ADVANCED CONCEPTS</p>
              </div>
            </div>
            <div className='course-details'>
              <div className='course-num'>10 VIDEOS</div>
              <div className='course-w'>|</div>
              <div className='course-t'>3 TEST</div>
              <div className='course-w'>|</div>
              <div className='course-t'>REWARDS</div>
            </div>
                </div>
              </span>
            </div>
          </div>
        </figure>
        <div className='button-container'>
          <div className='frame'>
            <button onClick={rotateRight}><i className='fas fa-backward'></i></button>
            <button onClick={rotateLeft}><i className='fas fa-forward'></i></button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Premium;

