import React, { useState, useEffect } from 'react';
import './Jargons.css';
import image2 from '../../Image/unsplash_afW1hht0NSs.png';
import Footer from '../../Footer/Footer';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Link } from 'react-router-dom';
import useScrollToTop from '../../Hooks/useScrollToTop';

function Jargons() {
  const [selectedLetter, setSelectedLetter] = useState('A'); 
  const [selectedLetterData, setSelectedLetterData] = useState([]);
  const [clickedLetter, setClickedLetter] = useState(null);
  const [expandedDefinitionId, setExpandedDefinitionId] = useState(null);

  useEffect(() => {
    fetch(`https://e-learning-backend-seven.vercel.app/get-alljargons`)
      .then((response) => response.json())
      .then((data) => {
        setSelectedLetterData(data.data);
        console.log(data, 'selected');
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
    setClickedLetter(letter);
    setExpandedDefinitionId(null); // Reset expanded definition
  };

  const handleExpandDefinition = (_id) => {
    setExpandedDefinitionId(_id === expandedDefinitionId ? null : _id);
  };

  useScrollToTop();

  // Filter data based on selected letter
  const filteredData = selectedLetterData.filter(
    (selectedJargon) => selectedJargon.term.charAt(0).toUpperCase() === selectedLetter
  );

  return (
    <div>
      <div className='Jargons-container'>
        <div className='Cluster-c'>
          <div className='back-jargons'>
            <Link to='/'>
              <ArrowCircleLeftOutlinedIcon sx={{width: "2em", fontSize: "3em",color:'#622E27'}}/>
            </Link>
            <p className='Cluster-text'>Cluster Of Terms Tech Jargons</p>
          </div>
        </div>
        <div className='abcd-C'>
          <p className='abcd-text'>
            {alphabet.map((letter) => (
              <span
                key={letter}
                onClick={() => handleLetterClick(letter)}
                className={`${clickedLetter === letter || (clickedLetter === null && letter === 'A') ? 'clicked' : ''}`}
              >
                {letter}
              </span>
            ))}
          </p>
        </div>
        <p className='total-jargons'>Total Jargons : {selectedLetterData.length}</p>
        <div className='Satoshi-C'>
          <div className='s-simpal-C'>
            <p className='s-simpal'>{selectedLetter}</p>
            <img src={image2} alt='' className='search-icon'></img>
          </div>
          <div className='Satoshi-cont'>
            <div className='Satoshi-con-text'>
              {filteredData.map((selectedJargon) => (
                <div className='Satoshi-con' key={selectedJargon._id}>
                  <p className='SATOSHI'>{selectedJargon.term}</p>
                  <p className='Satoshi'>
                    {expandedDefinitionId === selectedJargon._id
                      ? selectedJargon.definition
                      : selectedJargon.definition.split(' ').slice(0, 20).join(' ') + ' . '}
                    {selectedJargon.definition.split(' ').length > 20 && (
                      <button onClick={() => handleExpandDefinition(selectedJargon._id)} className='less-more'>
                        {expandedDefinitionId === selectedJargon._id ? 'Less' : 'More'}
                      </button>
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div className='NoData'>{filteredData.length === 0 && 'No data found'}</div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Jargons;
