import React from 'react'
import "./Demo.css"
function Demo() {
  return (
    <div className='pay-details-popup'>
      <div className='pay-popup-container'>
        <p className='plan-price-tag'>Price of the Price : Rs. 500</p>
        <span className='popup-reward-span'>The Reward point  you are eligible to use for the Purchase.</span>
        <h3 className='reward-h3'>Total Payable Price : Rs. 400</h3>
        <div className='popup-bottom-details'>
            <p className='reward-check-box'><input type='checkbox'  style={{
      fontSize: '16px',  
      border: '2px solid #ff0000'  
    }}/> Reward Points</p>
            <button>PAY NOW</button>
        </div>
      </div>
    </div>
  )
}

export default Demo
