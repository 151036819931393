import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './GrabSection.css'; // Import your custom CSS

function Main() {
  useEffect(() => {
    const swiper = new window.Swiper(".swiper", {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 3,
        slideShadows: true
      },
      keyboard: {
        enabled: true
      },
      mousewheel: {
        thresholdDelta: 70
      },
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      breakpoints: {
        640: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 1
        },
        1024: {
          slidesPerView: 2
        },
        1560: {
          slidesPerView: 3
        }
      }
    });

    // Destroy the Swiper instance when the component unmounts
    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <div className='grad-section'>
    <main className='grab-section-main'>
      <div className='grad-content-head-right'>
        <span>discover</span>
        <h1>CRADLE CRYPTO TERMS</h1>
        <hr />
        <p>"Ready to take the plunge into the crypto and blockchain revolution."
Our Meticulous Curated Technical Jargons Will Guide You From HODL to HASH RATE.</p>
       <Link to="/Jargons">Grab Here</Link>
      </div>
      <div className="swiper">
        <div className="swiper-wrapper">
          <div className="swiper-slide swiper-slide--one custom-slide" >
            <div>
              <h2>Beacon Chain</h2>
              <p>A blockchain that coordinates shard chains, manages staking and the registry of validators in a PoS cryptocurrency, such as Ethereum 2.0.</p>
            </div>
          </div>
          <div className="swiper-slide swiper-slide--two">
            <div>
              <h2>Liquid Staking</h2>
              <p>
              Liquid staking allows users to stake tokens and simultaneously use them in the DeFi ecosystem. 
13.Full Nodes - Nodes that download a blockchain’s entire history in order to observe and enforce its rules.              </p>
            </div>
          </div>

          <div className="swiper-slide swiper-slide--three">
            <div>
              <h2>Exchange</h2>
              <p>
              A system on which assets like cryptocurrencies can be bought, sold, and stored. Exchanges can be centralized where a company controls them; or decentralized (peer-to-peer).               </p>
            </div>
          </div>

          <div className="swiper-slide swiper-slide--four">
            <div>
              <h2>Ether</h2>
              <p>
              The digital currency of the Ethereum network. Ether is used to pay the transaction and processing fees of Ethereum decentralized applications and smart contracts.               </p>
            </div>
          </div>

          <div className="swiper-slide swiper-slide--five">
            <div>
              <h2>Blockchain Trilemma</h2>
              <p>
              The Blockchain Trilemma refers to the challenge in achieving a balance between scalability, decentralization, and security in a blockchain network. 
              </p>
            </div>
          </div>
        </div>
        {/* Add Pagination */}
        <div className="swiper-pagination"></div>
      </div>
      {/* <img src="https://cdn.pixabay.com/photo/2021/11/04/19/39/jellyfish-6769173_960_720.png" alt="" className="bg" />
      <img src="https://cdn.pixabay.com/photo/2012/04/13/13/57/scallop-32506_960_720.png" alt="" className="bg2" /> */}
    </main>
    </div>
  );
}

export default Main;
