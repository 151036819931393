import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Test.css";
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import { Link, useParams } from "react-router-dom";

const TestComponent = ({ match }) => {
  const [videosData, setVideosData] = useState([]);
  const [quizData, setQuizData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const { id } = useParams();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [quizResult, setQuizResult] = useState(null);
  const [uid, setUid] = useState(""); 
  
  const [loading , setloading] = useState(true)

  useEffect(() => {
  
    const fetchVideosData = async () => {
      try {
        const response = await axios.get(
          `https://e-learning-backend-seven.vercel.app/get-videosBy-planId/${id}`
        );
        setVideosData(response.data.data);
        setloading(false)
      } catch (error) {
        console.error("Error fetching videos data", error);
      }
    };

    const fetchQuizData = async () => {
      try {
        const response = await axios.get(
          "https://elearning-sand.vercel.app/get-all-quiz"
        );
        setQuizData(response.data.data);
        setloading(false)
      } catch (error) {
        console.error("Error fetching quiz data", error);
      }
    };

    fetchVideosData();
    fetchQuizData();
  }, [id]);

  useEffect(() => {
    const filteredData = quizData.filter((quizItem) => {
      const correspondingVideo = videosData.find(
        (videoItem) =>
          videoItem.level.toLowerCase() === quizItem.planName.toLowerCase()
      );
      return correspondingVideo !== undefined;
    });
    setloading(false)
    setDisplayedData(filteredData);

  }, [videosData, quizData]);

  useEffect(() => {

    const storedUid = localStorage.getItem("uid");
    if (storedUid) {
      setUid(storedUid);
    }
  }, []);

  const handleOptionSelect = (questionId, optionIndex) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [questionId]: optionIndex,
    }));
  };

  const handleSubmitQuiz = async () => {
    const quizPayload = displayedData.map((item) => ({
      questionID: item._id,
      answer:
        selectedOptions[item._id] !== undefined
          ? selectedOptions[item._id]
          : null,
    }));

    try {
    
      const videosResponse = await axios.get(
        `https://e-learning-backend-seven.vercel.app/get-videosBy-planId/${id}`
      );
      const { planId, _id } = videosResponse.data.data[0];

      const payload = {
        videoID: _id,
        planID: planId,
        uid: uid,
        QA: quizPayload,
      };
      setloading(false)
      console.log(payload, "pay");
   
      const response = await axios.post(
        "https://e-learning-backend-seven.vercel.app/quiz-verification",
        payload
      );

      setQuizResult(response.data);
      setloading(false)
   
    } catch (error) {
      console.error("Error submitting quiz:", error);
    }
  };

  const handleRetest = () => {
    setQuizResult(null);
    setSelectedOptions({});
  };

  const renderPopupContent = () => {
    if (!quizResult) {
      return null;
    }
    return (
      <div>
     
      
        {quizResult.truePercentage >= 75 ? (
             <div className="popup-content-test">
             <p className="test-popup-content">you have successfully passed the quiz, you have obtained above 70%, you are eligible to generate the certificate {quizResult.truePercentage +"%" }</p>
            <button onClick={handleGenerateCertificate} className="get-certificate">
              <Link to='/userprofile' style={{color: "white"}}>
              Generate Certificate
              </Link>
            </button> 
            </div>
        
        ) : (
          <div className="popup-content-test">
              <p className="test-popup-content">you are failed in the quiz ,take a retest to earn the certificate {quizResult.truePercentage }</p>
          <button onClick={handleRetest} className="quiz-retest-btn">
            Retest
          </button>
          </div>
        )}
      </div>
    );
  };

  const handleGenerateCertificate = async () => {
    try {
   
      const video = videosData[0]; 
      const { planId, _id: videoId, level: planName } = video;
      const storedUserName = localStorage.getItem("userName");
    
      const certificatePayload = {
        uid: uid,
        userName:storedUserName, 
        planName: planName,
        planID: planId,
        videoID: videoId,
      };


      const response = await axios.post(
        "https://e-learning-backend-seven.vercel.app/certification",
        certificatePayload
      );
      setQuizResult(null);
    
    } catch (error) {
      console.error("Error generating certificate:", error);
    }
  };

return (
  <div className="quiz-container">
    <h1 className="quiz-heading">Quiz Test</h1>
    {loading === true ? (
        <Skeleton variant="circular">
        <Avatar />
      </Skeleton>
    ) : (
   
      <ul className="quiz-options-list">
        { displayedData.length > 0 ? (
            <ul className="quiz-options-list">
            {displayedData.map((item, questionIndex) => (
              <li key={item._id} className="quiz-question">
                <p className="quiz-question">
                  {`Q${questionIndex + 1}. ${item.question}` || (
                    <Skeleton animation="wave" width={400} />
                  )}
                </p>
                <ul className="quiz-options">
                  {item.options.map((option, optionIndex) => (
                    <li key={optionIndex} className="quiz-option">
                      <label className="label-01">
                        <input
                          type="radio"
                          className="test-radio-btn"
                          id={`option_${optionIndex}`}
                          name={`question_${item._id}`}
                          checked={selectedOptions[item._id] === optionIndex}
                          onChange={() => handleOptionSelect(item._id, optionIndex)}
                        />
                        {option || <Skeleton animation="wave" width={300} />}
                      </label>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
         
          
        ) : (
              <div className="skaleton">
           
           {[...Array(4)].map((_, index) => (
          <div key={index} className="skaleton-C">
            <Box>
              <div className="skaleton-P"> 
                <Skeleton variant="circular" width={20} height={20} style={{marginTop:'10px'}} />
                <div>
                  <Skeleton animation="wave" sx={{ width: 500 , height:35 }} />
                  <Skeleton animation="wave" sx={{ width: 300 }} />
                  <Skeleton animation="wave" sx={{ width: 300 }} />
                  <Skeleton animation="wave" sx={{ width: 300 }} />
                  <Skeleton animation="wave" sx={{ width: 300 }} />
                </div>
              </div>
            </Box>
          </div>
        ))}
              
         </div>
        )       
    }
      </ul>
    )}
    <button className="submit-quiz-button" onClick={handleSubmitQuiz}>
      Submit Quiz
    </button>
    {quizResult && (
      <div className="popup-overlay">
        <div className="popup">
          <button
            className="close-button"
            onClick={() => setQuizResult(null)}
          >
            X
          </button>
          {renderPopupContent()}
        </div>
      </div>
    )}
  </div>
);

};

export default TestComponent;
