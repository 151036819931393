import React, { useState, useEffect } from 'react';
import './Articles.css';
import img from '../../Image/Vector (2).svg';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NavBar from "../../Nav/NavBar";

import { Link } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import useScrollToTop from '../../Hooks/useScrollToTop';

function Articles() {
  const [tier, setTier] = React.useState('');
  const [articleData, setArticleData] = useState([]);
  const [filteredArticleData, setFilteredArticleData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleTierChange = (event) => {
    const selectedTier = event.target.value;
    setTier(selectedTier);

    // Filter the data based on the selected tier and search query
    const filteredData = articleData.filter((article) => {
      const tierMatch = selectedTier === '' || article.tier === selectedTier;
      const titleMatch = article.title[0].heading.toLowerCase().includes(searchQuery.toLowerCase());
      return tierMatch && titleMatch;
    });

    setFilteredArticleData(filteredData);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);

    // Filter the data based on the search query
    const filteredData = articleData.filter((article) => {
      const tierMatch = tier === '' || article.tier === tier;
      const titleMatch = article.title[0].heading.toLowerCase().includes(event.target.value.toLowerCase());
      return tierMatch && titleMatch;
    });

    setFilteredArticleData(filteredData);
  };

  useEffect(() => {
    fetch('https://e-learning-backend-seven.vercel.app/get-all-treatise')
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          setArticleData(data.data);
          setFilteredArticleData(data.data); // Initially set filtered data to all data
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);
useScrollToTop()
  return (
    <div>
      <NavBar/>
       <div className='Artical-contain'>
        <div className='search-container'>
          <div className='single-code'></div>
          <div className='second-code'></div>
          <div className='third-code'></div>
          <div className="search">
            <input
              type="text"
              className="search__input"
              placeholder="Search Article"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <button className="search__button">
              <img src={img} className="search__icon" alt='' />
            </button>
          </div>
        </div>
        <div className='Art-botm'>
          <div className='card-collection'>
            <div className='card-post'>Recent Posts</div>
            <div>
              <Box>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={tier}
                    onChange={handleTierChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{ minWidth: 200, border: '1px solid white ', color: 'white', height: '6.5vh' }}
                  >
                    <MenuItem value="">
                      Select the Category
                    </MenuItem>
                    <MenuItem value={'tier1'}>Tier1</MenuItem>
                    <MenuItem value={'tier2'}>Tier2</MenuItem>
                    <MenuItem value={'tier3'}>Tier3</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          <div className='card-container'>
            {filteredArticleData.map((article) => (
              <Link to={`/get-treatise/${article._id}`} key={article._id}>
                <div className='Artical-card'>
                  <img src={article.treatisePicture} alt={article.title[0].heading} />
                  <div className='card-nick'>
                    <p>{article.title[0].heading}</p>
                    <span>{article.title[0].content}</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
     
        </div>
       </div>
       <Footer/>
    </div>
  );
}

export default Articles;

