import React, { useEffect } from "react";
import gsap from "gsap";
import "./Treatise.css";
import Arrow from "../Image/Arrow1.png";
import image1 from "../Image/3d-internet-secuirty-badge.jpg";
import image2 from "../Image/3eth.jpeg";
import image3 from "../Image/3628746_626.jpg";
import image4 from "../Image/walllet.jpg";
import image6 from "../Image/node1.jpg";
import image7 from "../Image/3eth.jpeg";
import image5 from "../Image/cardano-blockchain-platform.jpg";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

function Treatise() {
  useEffect(() => {
    const tl = gsap.timeline({
      repeat: Infinity,
    });

    const slides = document.querySelectorAll(".imgContainer");

    tl.to(
      slides,
      {
        ease: "expo.out",
        width: "43vw",
        duration: 10,
        stagger: 5,
      },
      "flag"
    )
      .to(
        ".heading h1",
        {
          ease: "expo.out",
          stagger: 5,
          duration: 2,

          top: "0%",
        },
        "flag"
      )
      .to(
        ".text",
        {
          ease: "expo.out",
          stagger: 5,
          duration: 2,
          top: "0%",
        },
        "flag"
      )
      .to(
        ".text",
        {
          delay: 5,
          ease: "expo.out",
          stagger: 5,
          duration: 1,
          opacity: 0,
        },
        "flag"
      )
      .to(
        ".heading h1",
        {
          delay: 5,
          ease: "expo.out",
          stagger: 5,
          duration: 1,
          opacity: 0,
        },
        "flag"
      );
  }, []);

  return (
    <div id="Treatise">
      <div className="Tre-container">
        <p className="TREATISE">TREATISE </p>
        <div className="treatise-container">
          <div className="T-con-text">
            <p className="TREATISE-text">EXPOSE THE CRYPTO UNIVERSE</p>
            <div className="our-solutions">
              <p className="tre-our-text">our solutions</p>
              <p className="we-provide">we provide full funnel aproch</p>
            </div>

            <p className="dive-into">
              Dive into a treasure trove of 50 Articles making breeze and they
              bridging the gap into the realm of Crypto and Blockchain in 3
              minutes of read
            </p>
            <div className="explore-btn">
              <Link to="/Articles">
                {" "}
                <button className="treatise">
                  EXPLORE IT{" "}
                  <img src={Arrow} className="expo-arrow" alt=""></img>{" "}
                </button>
              </Link>
            </div>
          </div>

          <div className="main">
            <div className="center">
              <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <div className="heading">
                <h1 className="Heading-h1">wallet</h1>
                <h1 className="Heading-h1">Faucet</h1>
                <h1 className="Heading-h1">Node</h1>
                <h1 className="Heading-h1">NFT</h1>
                <h1 className="Heading-h1">GAS</h1>
                <h1 className="Heading-h1">Tokens</h1>
                <h1 className="Heading-h1">Consensus</h1>
                <h1 className="Heading-h1">Mining</h1>
                <h1 className="Heading-h1">EVM</h1>
              </div>
              <div className="textContainer">
                <div className="text">
                  Cryptocurrency wallets are digital tools that store private
                  keys, enabling users to securely manage their digital assets,
                  send and receive payments, and monitor their portfolio.
                </div>
                <div className="text">
                  Faucets are platforms or applications that dispense small
                  amounts of cryptocurrency to users, often for educational
                  purposes, testing, or as rewards for participation.
                </div>
                <div className="text">
                  Nodes are integral components of blockchain networks,
                  responsible for validating transactions, maintaining the
                  ledger, and contributing to network consensus, ensuring
                  transparency and security.
                </div>
                <div className="text">
                  Cryptography, a cornerstone of blockchain technology, employs
                  mathematical algorithms to secure data, authenticate
                  transactions, and protect sensitive information in a
                  tamper-resistant manner.
                </div>
                <div className="text">
                  Web 2.0 represents the traditional internet with centralized
                  services, while Web 3.0 signifies a decentralized,
                  user-centric internet enabled by blockchain technology,
                  offering enhanced privacy, security, and ownership.
                </div>
                <div className="text">
                  Tokens are digital assets built on blockchain platforms,
                  serving diverse purposes, from representing ownership and
                  access to services to facilitating governance and utility
                  within decentralized ecosystems.
                </div>
                <div className="text">
                  The blockchain trilemma is a challenge in designing blockchain
                  networks, as it involves finding the delicate balance between
                  security, decentralization, and scalability, with trade-offs
                  between these essential attributes.
                </div>
                <div className="text">
                  The Ethereum Virtual Machine (EVM) is a runtime environment
                  for executing smart contracts on the Ethereum blockchain,
                  enabling the creation of decentralized applications (DApps)
                  with self-executing code.
                </div>
                <div className="text">
                  Smart contracts are self-executing, programmable agreements
                  that automatically execute predefined actions when conditions
                  are met, enhancing trust and automating processes on
                  blockchain networks.
                </div>
              </div>
              <div className="imgContainer">
                <img src={image1} alt="" className="tra-image" />
              </div>
              <div className="imgContainer">
                <img src={image4} alt="" className="tra-image" />
              </div>
              <div className="imgContainer">
                <img src={image6} alt="" className="tra-image" />
              </div>
              <div className="imgContainer">
                <img src={image2} alt="" className="tra-image" />
              </div>
              <div className="imgContainer">
                <img src={image7} alt="" className="tra-image" />
              </div>
              <div className="imgContainer">
                <img src={image5} alt="" className="tra-image" />
              </div>
              <div className="imgContainer">
                <img src={image5} alt="" className="tra-image" />
              </div>
              <div className="imgContainer">
                <img src={image3} alt="" className="tra-image" />
              </div>
              <div className="imgContainer">
                <img src={image2} alt="" className="tra-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Treatise;
