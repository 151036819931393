import React, { useState, useEffect } from "react";
import "./Raiseticket.css";
import UsePagination from "./TablePagination";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardCapslockIcon from "@mui/icons-material/KeyboardCapslock";
import imageSrc1 from "../../../Images/Check.png"
import { Link, useNavigate } from "react-router-dom";
import RaisedTickets from "./RaisedTickets";

const TicketTable = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("select a category");
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [popup, setPopup] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const Navigate = useNavigate();

  const handlePlatformChange = (event) => {
    setSelectedPlatform(event.target.getAttribute("data-value"));
    setOptionsVisible(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg')) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImageSrc(null);
      // Handle invalid file type error here
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const uploadToCloudinary = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ei4ydbhp");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dsnd6lado/image/upload",
        formData
      );

      return response.data.secure_url;
    } catch (error) {
      console.error("Failed to upload image:", error);
      return null;
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = localStorage.getItem("email");
    const requestData = {
      email,
      category: selectedPlatform,
      reason: text,
      screenShotURL: null,
    };

    if (file) {
      const uploadedImageUrl = await uploadToCloudinary(file);
      if (uploadedImageUrl) {
        requestData.screenShotURL = uploadedImageUrl;
      }
    }

    try {
      const response = await fetch(
        "https://e-learning-backend-seven.vercel.app/create-tickets",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        console.log("Ticket submitted successfully");
        setSelectedPlatform("Select a category");
        setFile(null);
        setText("");
        setPopup(true);
      } else {
        const errorData = await response.json();

        console.error("Failed to submit ticket:", errorData.error);
        toast.error("Failed to submit ticket. Try again later.");
      }
    } catch (error) {
      toast.error("Failed to submit ticket");
      console.error("Failed to submit ticket:", error);
    }
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");

    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const profileURL = localStorage.getItem("profileURL");

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  return (
    <div className="ticket-raised-main">
      <div className="ticket-raised-heading">
        <h2>Raise Ticket</h2>
      </div>
      <div className="raised-ticket-container">
        <div className="input-container">
          <div className="select-container">
            <div
              className={`select-input ${optionsVisible ? "open" : ""}`}
              onClick={toggleOptions}
            >
              {selectedPlatform}
              <span className="arrow-icon">
                <KeyboardArrowDownIcon />
              </span>
            </div>

            {optionsVisible && (
              <div className="options-container">
                <div onClick={handlePlatformChange} data-value="Payment_Issue">
                  Payment Issue
                </div>
                <div onClick={handlePlatformChange} data-value="Course_Issue">
                  Course Issue
                </div>
                <div onClick={handlePlatformChange} data-value="Plan_Upgrade">
                  Plan Upgrade
                </div>
                <div onClick={handlePlatformChange} data-value="Others">
                  Others
                </div>
              </div>
            )}
          </div>

          <div className="file-label-01">
            <label htmlFor="upload-button" style={{ cursor: "pointer" }}>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <span style={{ cursor: "pointer" }}>Upload</span>
            </label>
            <div className="uploaded-icons">
              <KeyboardCapslockIcon />
            </div>
           
      {imageSrc && <img src={imageSrc1} alt="Uploaded"  style={{width: "23px", height: "auto"}}/>}
          </div>
        </div>
        <div className="query-box">
          <div className="quert-text-area-div">
            <textarea
              className="quert-text-area"
              value={text}
              onChange={handleTextChange}
              placeholder="Describe your query"
            ></textarea>
          </div>
        </div>
        <div className="button-container-02">
          <button
            className="save-btn-01"
            onClick={handleSubmit}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Save"}
          </button>
          
        </div>
      </div>
      <RaisedTickets />
    </div>
  );
};

export default TicketTable;
