import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SignIn from "../SignIn/SignIn";

function SignInMain() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      navigate("/");
    }
  }, []);

  return <div>{isLoggedIn ? <p>Pofile page</p> : <SignIn />}</div>;
}

export default SignInMain;
