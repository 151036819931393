import React, { useState } from "react";
import * as Components from "../Components";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

import { Link } from "react-router-dom";

function SignIn() {
  const [user, setUser] = useState(null);

  const [formData, setFormData] = useState({ email: "", password: "" });

  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const requestOptions = {
      method: "GET",

      headers: { "Content-Type": "application/json" },
    };
    fetch(
      "https://e-learning-backend-seven.vercel.app/user-login/" +
        formData?.email +
        "/" +
        formData?.password,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        return response.json();
      })

      .then((data) => {
        localStorage.setItem("token", data?.data?.token);

        localStorage.setItem("email", data?.data?.email);
        console.log(data, "token");

        if (data?.data?.email) {
          setSuccessMessage(
            swal({
              title: "Successfully!",

              text: "SignIn Successfully please check your Profile ",

              formData,

              icon: "success",
            })
          );

          navigate("/");

          const profileRequestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + data?.data?.token,
            },
            body: JSON.stringify({ email: data?.data?.email }),
          };
          fetch(BASE_URL + "dashboard-profile", profileRequestOptions)
            .then((response) => response.json())

            .then((profileData) => {
              console.log("First Name:", profileData?.data?.firstName);
              console.log("First me:", profileData?.data);

              localStorage.removeItem("firstName");

              localStorage.removeItem("uid");

              localStorage.removeItem("profileURL");

              localStorage.setItem(
                "firstName",
                profileData?.data[0]?.firstName
              );
              localStorage.setItem(
                "userName",
                profileData?.data[0]?.firstName+profileData?.data[0]?.lastName
              );
              localStorage.setItem("uid", profileData?.data[0]?.uid);

              localStorage.setItem(
                "profileURL",
                profileData?.data[0]?.profileURL
              );

              const updatedFirstName = localStorage.getItem("firstName");

              const updatedUid = localStorage.getItem("uid");

              const updatedProfileURL = localStorage.getItem("profileURL");

              console.log(updatedProfileURL, "profile");

              console.log(
                "Updated profile details:",
                updatedFirstName,
                updatedUid
              );

              setUser(profileData?.data);
            })

            .catch((error) => ("Error fetching profile data", error));
        }
      })

      .catch((error) => {
        toast.error("Invalid email or password ");
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <Components.Form onSubmit={handleSubmit}>
      <Components.Title>Sign in</Components.Title>
      <Components.Input
        type="email"
        name="email"
        placeholder="Email"
        style={{ width: "19vw" }}
        value={formData.email}
        onChange={handleInputChange}
      />
      <Components.Input
        type="password"
        name="password"
        placeholder="Password"
        style={{ width: "19vw" }}
        value={formData.password}
        onChange={handleInputChange}
      />
      <Link to="/ForgetPassword">
        <Components.Anchor>Forgot your password?</Components.Anchor>
      </Link>

      <Components.Button>Log In</Components.Button>
      {successMessage && (
        <p style={{ backgroundColor: "blue" }}>{successMessage}</p>
      )}
      <ToastContainer />
    </Components.Form>
  );
}

export default SignIn;
