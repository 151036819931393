import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import useScrollToTop from '../../../Hooks/useScrollToTop';
import Footer from '../../../Footer/Footer';


function PrivacyPolicy() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
     <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 

     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>PAYMENT TERMS AND CONDITIONS POLICY</h1>
    <h5>Payment Obligations</h5>
    <p>By using the INOCYX learning platform, you agree to pay the fees associated with 
the courses, programs, or services offered. All fees are quoted in the currency specified 
on the platform and are exclusive of any applicable taxes or charges unless otherwise 
stated.</p>
<h5>Payment Methods</h5>
<p>The INOCYX learning platform will provide available payment methods, such as 
credit/debit cards, other third-party payment processors. You are responsible for 
providing accurate and up-to-date payment information. The INOCYX learning 
platform may store and process your payment information in accordance with its 
privacy policy. </p>
<h5>Pricing and Discounts</h5>
<p> The INOCYX learning platform may offer promotional pricing or discounts on 
courses, programs, or services. Pricing and discounts are subject to change at the 
discretion of the learning platform. Discounts or promotional offers may have specific 
terms and conditions that apply.</p>
<h5>Security</h5>
<p>The INOCYX learning platform will take reasonable measures to ensure the security 
of your payment information. However, the platform cannot guarantee the absolute 
security of data transmission or storage and shall not be liable for any unauthorized 
access or breaches.
</p>
<h5>Modifications to Payment Terms</h5>
<p> The INOCYX learning platform reserves the right to modify or update these payment 
terms and conditions at any time. Any changes will be effective upon posting the 
revised policy on the platform, and your continued use of the platform constitutes 
acceptance of the modified terms</p>
  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
      <Footer/>
      </div>
    </div>
  )
}

export default PrivacyPolicy