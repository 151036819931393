import React, { useState } from 'react';
import image1 from "../../Image/Group (3).png";
import image2 from "../../Image/message-3-line.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import "./Contactus.css";
import Footer from '../../Footer/Footer';
import useScrollToTop from '../../Hooks/useScrollToTop';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

function Contactus() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const navigate =  useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://e-learning-backend-seven.vercel.app/contactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
       toast.success('message sent successfully')
       navigate('/')
      } else {
        console.error('Failed to send message');
        toast.error('Failed to send message.All fields is Required',)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

useScrollToTop()
  return (
   <div>
         <div className='PrivacyPolicy-back-btn'>
<Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
</div>
     <div className='Contactus-container'>

      <p className='text-contactus-head'>Contact us</p>
      <p className='text-contactus-content'>PLEASE ENTER YOUR INFORMATION AND DESCRIBE YOUR INQUIRY, AND WE’LL GET BACK TO YOU AS SOON AS POSSIBLE.</p>
      <form onSubmit={handleSubmit}>
        <div className='text-contact-us-inputs'>
          <div className='input-contactus'>
            <div className='input-name-Contactus'>
              <p className='name-contactus'>Name</p>
              <input
                className='input-contactus-text'
                type='text'
                placeholder='Name here'
                name='name'
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className='input-name-Contactus'>
              <p className='name-contactus'>Email</p>
              <input
                className='input-contactus-text'
                type='email'
                placeholder='Example@mail.eru'
                name='email'
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className='input-name-Contactus'>
              <p className='name-contactus'>Message</p>
              <textarea
                className='input-contactus-textarea'
                placeholder='Start typing'
                name='message'
                value={formData.message}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='Address-cont'>
            <div className='image-email-add'>
              <img src={image2} alt='' className='image-add'></img>
              <div>
                <p className='Address-cont-email'>E-mail</p>
                <p className='Address-cont-text'>help@inocyxlearning.com</p>
              </div>
            </div>
            <div className='image-email-add'>
              <img src={image1} alt='' className='image-add'></img>
              <div>
                <p className='Address-cont-email'>Address</p>
                <p className='Address-cont-text'>STPI 2ND FLOOR FIN BLUE (CABIN #16), <br/> NO.5, Rajiv Gandhi Salai, Tharamani.<br/> Chennai,<br/> Tamil Nadu 600113</p>
              </div>
            </div>
          </div>
        </div>
        <div className='send-message-con'>
          <button className='send-message' type='submit'>Send message</button>
        </div>
      </form>
     <ToastContainer/>
    </div>
    <Footer/>
   </div>
  );
}

export default Contactus;
