import React, { useState, useEffect } from 'react';
import './Feedbackhelp.css';
import axios from 'axios';
import image1 from '../../Image/Down arrow.png';
import image2 from "../../Image/Cancel (2).png"
import { ToastContainer, toast } from "react-toastify";
import image3 from "../../Image/Check (1).png"
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import useScrollToTop from '../../Hooks/useScrollToTop';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
function Feedbackhelp() {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackData, setFeedbackData] = useState(null);
  const [createdAt, setCreatedAt] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const Navigate = useNavigate(); 

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };
  
  const handlePlatformChange = (e) => {
    setSelectedPlatform(e.target.value);
    setOptionsVisible(!optionsVisible);
  };

  const submitFeedback = async () => {
    const email = localStorage.getItem('email');

    const data = {
      title: selectedPlatform,
      message: feedbackMessage,
      email: email,
    };
    try {
      await axios.post('https://e-learning-backend-seven.vercel.app/new-feedback', data);
      console.log('Feedback saved successfully.');


      setShowPopup(true)
    } catch (error) {
      toast.error("Failed to submit feedback. Try again later.");
      console.error('Error submitting feedback:', error);
    }

    setSelectedPlatform('');
    setFeedbackMessage('');
  };

  useEffect(() => {
    const fetchFeedbackData = async () => {
      try {
        const response = await axios.get('https://e-learning-backend-seven.vercel.app/get-all-feedback');
        const fetchedData = response.data;
        
        setFeedbackData(fetchedData);
      } catch (error) {
        console.error('Error fetching feedback data:', error);
      }
    };

    fetchFeedbackData();
  }, []);

  const email = localStorage.getItem('email');
  const profile = localStorage.getItem('profileURL');
  useScrollToTop()

  return (
    <div>
            <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
      <div className='main-tp'>
        <h2>Submit Feedback</h2>
        <div className='feedback-title'>
          <form id='app-cover'>
            <div id='select-box'>
              <p>Why you like INOCYX learning:</p>
              <input
                type='checkbox'
                id='options-view-button'
                checked={optionsVisible}
                onChange={toggleOptions}
              />
              <div id="select-button" onClick={toggleOptions}>
          <div id="selected-value">
            <span>{selectedPlatform} </span>
          </div>
          <div id='chevrons'>
                  <img src={image1} alt='' width={20} height={20}></img>
                </div>
        </div>
        {optionsVisible && (
          <div id="options">
            <div className="option">
            </div>
            <div className="option">
              <input
                className="s-c top"
                type="radio"
                name="platform"
                value="Awesome Platform"
                onChange={handlePlatformChange}
              />
              <span className="label">Awesome Platform</span>
            </div>
            <div className="option">
              <input
                className="s-c top"
                type="radio"
                name="platform"
                value="Excellent"
                onChange={handlePlatformChange}
              />
              <span className="label">Excellent</span>
            </div>
            <div className="option">
              <input
                className="s-c top"
                type="radio"
                name="platform"
                value="Others"
                onChange={handlePlatformChange}
              />
              <span className="label">Others</span>
            </div>
          </div>
        )}

            </div>
            <div className='feedback-comment'>
              <p>Type your valuable feedback here :</p>
              <div className='input-comment'>
                <textarea
                  type='text'
                  className='my-input'
                  placeholder='Enter your text here'
                  value={feedbackMessage}
                  onChange={(e) => setFeedbackMessage(e.target.value)}
                />
                <div className='feedback-submit' onClick={submitFeedback}>
                  <p>Submit</p>
                </div>
              </div>
            </div>
          </form>
        </div>
     
      </div>
      <Footer/>
    </div>
  );
}

export default Feedbackhelp;
