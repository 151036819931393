import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import useScrollToTop from '../../../Hooks/useScrollToTop';
import "./Term&Condition.css"
import Footer from '../../../Footer/Footer';

function TermConditions() {
  useScrollToTop();
  return (
    <div className='termcondition-container'>
     <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='termcondition-middle'>
     <div className='termcondition-main'>
       <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>
       TERMS & CONDITIONS
       </h1>
<p>Welcome to INOCYX, the website and mobile service of INOCYX Private Limited 
Company (“INOCYX,” “we,” or “us”).
</p>
<p>These terms and conditions outline the rules and regulations for the use of INOCYX's 
Website, located at <a href='https://www.inocyxlearning.com/'  style={{textDecoration: "underline"}}>www.inocyxlearning.com</a></p>
<p>By accessing this website, we assume you accept these terms and conditions. Do not 
continue to use INOCYX Learning if you do not agree to take all of the terms and 
conditions stated on this page.</p>
<p>The following terminology applies to these Terms and Conditions, Privacy Statement 
and Disclaimer Notice and all Agreements: "Client", "You" and """Your" refers to you, 
the person log on this website and compliant to the Company's terms and conditions. 
"The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", 
"Parties", or "Us", refers to both the Client and us. All terms refer to the offer, acceptance 
and consideration of payment necessary to undertake the process of our assistance to 
the Client in the most appropriate manner for the express purpose of meeting the 
Client's needs in respect of provision of the Company's stated services, in accordance 
with and subject to, prevailing law of in. Any use of the above terminology or other 
words in the singular, plural, capitalization and/or he/she or they, are taken as 
interchangeable and therefore as referring to same.
</p>
<h5>Cookies</h5>
<p>We employ the use of cookies. By accessing INOCYX Learning, you agreed to use 
cookies in agreement with the INOCYX's Privacy Policy.</p>
<p>Most interactive websites use cookies to let us retrieve the user's details for each visit. 
Cookies are used by our website to enable the functionality of certain areas to make it 
easier for people visiting our website. Some of our affiliate/advertising partners may also 
use cookies.</p>
<h5>Licence</h5>
<p>Unless otherwise stated, INOCYX and/or its licensors own the intellectual property 
rights for all material on INOCYX Learning. All intellectual property rights are reserved. 
You may access this from INOCYX Learning for your own personal use subjected to 
restrictions set in these terms and conditions.</p>
<p>you must not :</p>
<ul>
  <li><p> Republish material from INOCYX Learning</p></li>
  <li><p> Sell, rent or sub-license material from INOCYX Learning</p></li>
  <li><p>Reproduce, duplicate or copy material from INOCYX Learning</p></li>
  <li><p> Redistribute content from INOCYX Learning</p></li>
  <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created 
with the help of the Free<a href='https://www.termsandconditionsgenerator.com/' style={{textDecoration: "underline"}}> Terms and Conditions Generator.</a></p>
</ul>
<p>Parts of this website offer an opportunity for users to post and exchange opinions and 
information in certain areas of the website. INOCYX does not filter, edit, publish or 
review Comments prior to their presence on the website. Comments do not reflect the
views and opinions of INOCYX, its agents and/or affiliates. Comments reflect the views 
and opinions of the person who post their views and opinions. To the extent permitted 
by applicable laws, INOCYX shall not be liable for the Comments or for any liability, 
damages or expenses caused and/or suffered as a result of any use of and/or posting of 
and/or appearance of the Comments on this website.
</p>
<p>INOCYX reserves the right to monitor all Comments and to remove any Comments 
which can be considered inappropriate, offensive or causes breach of these Terms and 
Conditions.</p>
<p>You warrant and represent that:</p>
<ul>
  <li><p> You are entitled to post the Comments on our website and have all necessary 
licenses and consents to do so;</p></li>
  <li><p> The Comments do not invade any intellectual property right, including without 
limitation copyright, patent or trademark of any third party;</p></li>
  <li><p> The Comments do not contain any defamatory, libelous, offensive, indecent or 
otherwise unlawful material which is an invasion of privacy</p></li>
  <li><p> The Comments will not be used to solicit or promote business or custom or 
present commercial activities or unlawful activity.</p></li>
</ul>
<p>You hereby grant INOCYX a non-exclusive license to use, reproduce, edit and authorize 
others to use, reproduce and edit any of your Comments in any and all forms, formats 
or media.</p>
<h5>Hyperlinking to our Content</h5>
<p>The following organizations may link to our website without prior written approval:</p>
<ul>
  <li><p> Government agencies;</p></li>
<li><p> Search engines;</p></li>
<li><p> Online directory distributors may link to our website in the same manner as they 
hyperlink to the Websites of other listed businesses; and</p></li>
<li><p> System wide Accredited Businesses except soliciting non-profit organizations, 
charity shopping malls, and charity fundraising groups which may not hyperlink 
to our Web site.</p></li>
</ul>
<p>These organizations may link to our home page, to publications or to other Website 
information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply 
sponsorship, endorsement or approval of the linking party and its products and/or 
services; and (c) fits within the context of the linking party's site.</p>
<p>We may consider and approve other link requests from the following types of 
organizations:</p>
<ul>
  <li><p> commonly known consumer and/or business information sources;</p></li>
  <li><p> dot.com community sites;</p></li>
  <li><p> associations or other groups representing charities;</p></li>
  <li><p> online directory distributors;</p></li>
  <li><p>internet portals;</p></li>
  <li><p> accounting, law and consulting firms; and</p></li>
  <li><p> educational institutions and trade associations</p></li>
</ul>
<p>We will approve link requests from these organizations if we decide that: (a) the link 
would not make us look unfavourably to ourselves or to our accredited businesses; (b) 
the organization does not have any negative records with us; (c) the benefit to us from 
the visibility of the hyperlink compensates the absence of INOCYX; and (d) the link is 
in the context of general resource information.</p>
<p>These organizations may link to our home page so long as the link: (a) is not in any way 
deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the 
linking party and its products or services; and (c) fits within the context of the linking 
party's site.
</p>
<p>If you are one of the organizations listed in paragraph 2 above and are interested in 
linking to our website, you must inform us by sending an e-mail to INOCYX. Please 
include your name, your organization name, contact information as well as the URL of 
your site, a list of any URLs from which you intend to link to our website, and a list of 
the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
<p>Approved organizations may hyperlink to our website as follows:
</p>
<ul>
  <li><p> By use of our corporate name; or</p></li>
  <li><p>By use of the uniform resource locator being linked to; or</p></li>
  <li><p>By use of any other description of our website being linked to that makes sense 
within the context and format of content on the linking party's site.</p></li>
</ul>
<p>No use of INOCYX's logo or other artwork will be allowed for linking absent a trademark 
license agreement.</p>
<h5>I Frames</h5>
<p>Without prior approval and written permission, you may not create frames around our 
Webpages that alter in any way the visual presentation or appearance of our website.</p>
<h5>Content Liability</h5>
<p>We shall not be hold responsible for any content that appears on your Website. You 
agree to protect and defend us against all claims that is rising on your Website. No 
link(s) should appear on any Website that may be interpreted as libelous, obscene or 
criminal, or which infringes, otherwise violates, or advocates the infringement or other 
violation of, any third-party rights.</p>
<h5>Reservation of Rights</h5>
<p>We reserve the right to request that you remove all links or any particular link to our 
website. You approve to immediately remove all links to our Website upon request. We 
also reserve the right to amen these terms and conditions and it's linking policy at any 
time. By continuously linking to our website, you agree to be bound to and follow these 
linking terms and conditions.
</p>
<h5>Removal of links from our website</h5>
<p>If you find any link on our website that is offensive for any reason, you are free to contact 
and inform us any moment. We will consider requests to remove links, but we are not 
obligated to or so or to respond to you directly.</p>
<p>If you find any link on our website that is offensive for any reason, you are free to contact 
and inform us any moment. We will consider requests to remove links, but we are not 
obligated to or so or to respond to you directly.</p>

       

       </div>
      
     </div>
  <Footer/>
    </div>
  )
}

export default TermConditions