import React, { useState, useEffect } from "react";
import Footer from "../../../Footer/Footer";


function ProfilePurchase() {
  const [userPurchases, setUserPurchases] = useState([]);

  useEffect(() => {
 
    const userEmail = localStorage.getItem("email");
    
    if (userEmail) {
      fetch(`https://e-learning-backend-seven.vercel.app/get-one-user-purchase/${userEmail}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setUserPurchases(data.data);
          } else {
            console.error("Failed to fetch user purchases");
          }
        })
        .catch((error) => {
          console.error("Error fetching user purchases:", error);
        });
    } else {
      console.error("User email not found in local storage");
    }
  }, []); 

  return (
    <div>
    <div className="recent-purchase-main">
    
        <table className="purchase-table">
          <thead>
            <tr>
              <th scope="col" style={{ width: "5vw" }} className="purchase-table"></th>
              <th scope="col" style={{ width: "20vw" }} className="purchase-table">
                Purchase Title
              </th>
              <th scope="col" style={{ width: "30vw" }} className="purchase-table">
                Type
              </th>
              <th scope="col"  style={{ width: "20vw" }} className="purchase-table">
                Date
              </th>
              <th scope="col"  style={{ width: "15vw" }} className="purchase-table">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {
              userPurchases.length > 0 ? (
                userPurchases.map((purchase, index) => (
                  <tr key={index}>
                    <td data-label="Account">{index + 1}</td>
                    <td data-label="Purchase Title">{purchase.planName}</td>
                    <td data-label="Type">{purchase.videoTitle || 'Plans'}</td>
                    <td data-label="Date">{new Date(purchase.purchaseDate).toLocaleString()}</td>
                    <td data-label="Amount">{purchase.amount}</td>
                  </tr>
                ))
              ) : (
                <tr>
                <td colSpan="5" style={{textAlign:'center'}}>No datas found</td>
              </tr>
              )
             }
          </tbody>
        </table>

    </div>
    <div style={{marginTop: "3rem"}}>
    <Footer/>
    </div>
    </div>
  );
}

export default ProfilePurchase;
