import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import world from "../../../Images/Group 5491.png"
import doller from "../../../Images/Vector (1).png"
import check from "../../../Images/Check.png"
import { Link } from 'react-router-dom';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import "./CraseCourse.css"
function CrashCurriculum() {

  const { crashCourseId } = useParams();
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // Fetch data from your API based on the plan ID
    fetch(`https://e-learning-backend-seven.vercel.app/get-videosBy-planId/${crashCourseId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setVideos(data.data);
      })
      .catch((error) => {
       
        console.error('Error fetching data:', error);
      });
  }, [crashCourseId]);
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Retrieve email from local storage
    const emailFromLocal = localStorage.getItem("email");

    if (emailFromLocal) {
      setEmail(emailFromLocal);
    }
  }, []);
  const storedProfilePic = localStorage.getItem("profilePic");

  console.log(videos , "crash course")

  return (
    <div className='CrashCurriculum-contain'>
         <div className='CrashCurriculum-nav'>
         <Link to="/"> <div className='back-arrow-02'>
          <ArrowCircleLeftOutlinedIcon sx={{width: "2em", fontSize: "3em"}}/>
        </div></Link>
         {email ? (
          
              <Link to="/userprofile">
                <img src={storedProfilePic || 'https://cdn3.iconfinder.com/data/icons/business-round-flat-vol-1-1/36/user_account_profile_avatar_person_student_male-512.png'} alt='ProfileImage' width="50px" height="50px" className="profile-image-nav" />
              </Link>
          
          ) : (
            <a href='/SignIn' style={{color: "white"}}>        
              <div className='avator'>
                <div className="signIn-btn">
                  <div className='ul-2-li'>
                    <p>SignIn</p>
                  </div>
                </div>
              </div>
            </a>
          )}
        </div>
        <div className='CrashCurriculum-mid-container'>
            <img src={world} alt='' className='world'/>
            <h1>Crash Curriculum</h1>
            <img src={doller} alt=''  className='doller'/>
        </div>
       
        <div className='CrashCurriculum-body-container' >
        <div className="blur disabled">
            <div className='CrashCurriculum-body-title'>
                <h1>{videos.length}VIDEO</h1>
            </div>
            <div className='CrashCurriculum-body'>
            {videos.map((video) => (
           <div>
             <div className='CrashCurriculum-body-video'>
            <div className='CrashCurriculum-video-left'>
            <div className='CrashCurriculum-pora'>
          <img src={check} alt='chorno' />
          <p>You enrolled this plan</p>
         </div>
         <video src={video.videoUrl} alt='' className='trinity2'/>
         <p className='CrashCurriculum-duration'>Duration : 3:45</p>
            </div>
            <div className='CrashCurriculum-video-right'>
                <div className='CrashCurriculum-right-title'>
                <h1>{video.title}</h1>
                <p>{video.description}</p>
                 <div className='CrashCurriculum-right-button'>
                <Link to= {`/get-videosBy-planId/${video._id}`}><p>Enroll</p></Link>  
                  <span>INR {video.price}</span>
                </div> 
                </div>
            </div>
         
            </div>
            <div className='textbtn-contain-1'>
      <button type="button">Test</button>
     </div>
           </div>
             ))}
                         
            </div>
        </div>
        </div>
     
    </div>
  )

}

export default CrashCurriculum