import React from 'react'
import useScrollToTop from '../../Hooks/useScrollToTop';
import Footer from '../../Footer/Footer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

function Reward() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
     <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins',fontVariant:'all-petite-caps'}}>Reward Policy
</h1>

   <p> This Reward Policy ("Policy") applies to any subscriptions purchased for INOCYX 
Learning platform ("Subscription") by paying a subscription fee ("Subscription Fee") 
on <a href='https://www.inocyxlearning.com/' style={{textDecoration: "underline"}}> www.inocyxlearning.com </a> ("Platform") made available by INOCYX Technologies 
Private Limited ("Company"). </p>

<p>Our Reward Points Program is designed to enhance your educational journey by 
offering you opportunities to earn and redeem points for various benefits. Here are the 
key aspects of our Reward Policies.</p>
<h5>Earning Reward Points:</h5>
<p> For every course the user purchase on INOCYX Learning Platform, user will earn 
Reward Points equivalent to 10% of the total course price. The more user learns, the 
more points user accumulates. User can redeem your accumulated Reward Points 
when making a new course purchase on INOCYX Learning Platform. This provides 
user with a discount on the total cost of the course. Users are eligible to claim a 
maximum of 20% of the course price in Reward Points. This ensures that user enjoy 
the benefits of your points while still contributing to the cost of your educational 
investment. While user can use up to 20% of the course price in Reward Points, the 
remaining 80% must be paid using one of our available payment methods.
</p>
<h5>How to Redeem Your Reward Points:</h5>
<p>Explore our extensive catalogue of courses and find the one that aligns with user 
learning goals. When user proceed to checkout, user will have the option to redeem 
their Reward Points. Simply choose the amount you wish to use. User Reward Points 
will be deducted from the total course price, and user will see the adjusted amount 
before confirming their payment. Reward Points are valid for a specified period up to 2 
months from the Purchasing date. Be sure to check the expiration date to make the 
most of user earned points. Reward Points are non-transferable and can only be used 
by the account holder who earned them.</p>


  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
     <Footer/>
    </div>
    </div>
  )
}

export default Reward