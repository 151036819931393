import React, { useState} from 'react';
import "./CardSection.css"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function PlanCard() {
  const [activeIndex, setActiveIndex] = useState(1);

  const handleMoveLeft = () => {
    setActiveIndex((activeIndex - 1 + 3) % 3); // Cycle through indexes 0, 1, 2
  };

  const handleMoveRight = () => {
    setActiveIndex((activeIndex + 1) % 3); // Cycle through indexes 0, 1, 2
  };
  const cardSets = [
    [
      // Cards for set 1
      <div className='plancard-Card' key='1'>
      <div className='plancard-Card-img'>
           <img src='https://static.vecteezy.com/system/resources/previews/008/550/522/original/character-boy-with-virtual-reality-device-metaverse-3d-illustration-png.png' alt='Plancard' />
           </div>
           <div className='plancard-Card-content'>
           <h2>Beginner</h2>
           <p>
            Once the content is displayed, the animation class is removed
            </p>
            <span>Free</span>
            <div className='purchases'><Link to='/PlansDetails/6489b5b48724a7fc3aab1492' style={{ textDecoration: 'none',color:'white' }}><p>purchase</p></Link></div>
           </div>
            </div>,
      <div className='plancard-Card-another' key='2'>
      <div className='plancard-Card-content1' >
           <h2>Beginner</h2>
            <p>
            Once the content is displayed, the animation class is removed
            </p>
            <span>Free</span>
            <div className='purchases'><Link to='/PlansDetails/6489b5b48724a7fc3aab1492' style={{ textDecoration: 'none',color:'white' }}><p>purchase</p></Link></div>
           </div>
      </div>
    ],
    [
      // Cards for set 2
      <div className='plancard-Card' key='3'>
            <div className='plancard-Card-img' >
           <img src='https://cdn3d.iconscout.com/3d/premium/thumb/boy-experiencing-metaverse-5077828-4241321.png' alt='Plancard'/>
           </div>
           <div className='plancard-Card-content'>
           <h2>Intermediate</h2>
            <p>
            Once the content is displayed, the animation class is removed
            </p>
            <span>INR 2000 / month</span>
            <div className='purchase'> <Link to='/PlansDetails/6489b7cd8724a7fc3aab1495' style={{ textDecoration: 'none',color:'white' }}><p>purchase</p></Link></div>
           </div>
      </div>,
      <div className='plancard-Card-another' key='4'>
        <div className='plancard-Card-content1' >
           <h2>Intermediate</h2>
            <p>
            Once the content is displayed, the animation class is removed
            </p>
            <span>INR 2000 / month</span>
           <div className='purchase'> <Link to='/PlansDetails/6489b7cd8724a7fc3aab1495' style={{ textDecoration: 'none' ,color:'white'}}><p>purchase</p></Link></div>
           </div>
      </div>
    ],
    [
      // Cards for set 3
      <div className='plancard-Card' key='5'>
            <div className='plancard-Card-img'>
           <img src='https://skyversenft.com/static/media/roadmap.df9205fc2e3bc8ce6328.png' alt='Plancard' />
           </div>
           <div className='plancard-Card-content'>
           <h2>Advance</h2>
            <p>
            Once the content is displayed, the animation class is removed
            </p>
            <span>INR 3000 / month</span>
            <div className='purchase'>
              <Link to='/PlansDetails/6489b7df8724a7fc3aab1498' style={{ textDecoration: 'none' ,color:'white'}}>
              <p>purchase</p>
              </Link>
              </div>
           </div>
      </div>,
      <div className='plancard-Card-another' key='6'>
        <div className='plancard-Card-content1' >
           <h2>Advance</h2>
            <p>
            Once the content is displayed, the animation class is removed
            </p>
            <span>INR 3000 / month</span>
            <div className='purchases'>
              <Link to='/PlansDetails/6489b7df8724a7fc3aab1498' style={{ textDecoration: 'none' ,color:'white'}}>
              <p>purchase</p>
              </Link></div>
           </div>
      </div>
    ]
  ];
  const handleSetClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <div className='plancard-contain'>
      <div className='plancard-title'>
       <h1>Fuel your learning curiosity</h1>
       <div className='plancard-top-right'>
            <KeyboardArrowLeftIcon className='arrow' onClick={handleMoveLeft} />
            <KeyboardArrowRightIcon className='arrow' onClick={handleMoveRight} />
          </div>
      </div>
      <div className='plancard-section'>
        <div className='plancard-top'>
        <Link to="/Trinity">         <div className='seize'>
            <Button>Seize It</Button>
        
           <p></p>
           <ArrowForward/>
          </div></Link>
        </div>
        <div className='plancard-btm'>
          {cardSets.map((cardSet, index) => (
            <div
              key={index}
              className={index === activeIndex ? 'plancard-Card-another active' : 'plancard-Card active'}
              onClick={() => handleSetClick(index)}
          >
              {cardSet}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PlanCard;






