import React, { useState, useEffect } from "react";
import image14 from "../Image/OIP (1).jpeg";
import image2 from "../Image/Vector (17).png";
import image from "../Image/Vector 28.png";
import insta from "../Image/Vector (23).png";
import linked from "../Image/Vector (22).png";
import img3 from "../Image/Vector (21).png";
import img4 from "../Image/Vector (20).png";
import img5 from "../Image/JournalBookmarkFill.png";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import image15 from "../Image/yusuf.jpeg";
import image17 from "../Image/b2.png"
import "./About.css";
import Footer from "../Footer/Footer";
import image16 from "../Image/OIP1.jpeg";
import { Link } from "react-router-dom";
import Nav from "../Nav/NavBar";
const chunkArray = (array, chunkSize) => {
  return array.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!result[chunkIndex]) {
      result[chunkIndex] = []; // start a new chunk
    }

    result[chunkIndex].push(item);

    return result;
  }, []);
};

function About() {
  const developers = [
    {
      id: 1,
      name: "Mohan Babu",
      email: "developer1@example.com",
      location: "City 1, Country 1",
      position: "CEO",
      images: image14,
      description:
        " As you know, the world is rapidly evolving, and so is the way we learn and acquire new skills. Our eLearning platform is poised to revolutionize the way education is delivered and accessed.",
    },
    {
      id: 2,
      name: "Yusuf Rahman",
      email: "developer2@example.com",
      location: "City 2, Country 2",
      position: "CFO",
      images: image15,
      description : 'I hope this message finds you well. Today, I am excited to shed light on the financial aspects of our new eLearning web application. As we know, strategic investments play a crucial role in the growth and success of our company, and our eLearning platform is no exception.',
    },
    {
      id: 3,
      name: "SivaChandran",
      email: "developer3@example.com",
      location: "City 3, Country 1",
      position: "CTO",
      images: image16,
      description : 'I am excited to share some insights into the technical marvel that is our new eLearning web application. As the Chief Technology Officer, I want to highlight the innovations and features that make our platform stand out in the digital landscape.',
    },
    {
      id: 4,
      name: "Balaji",
      email: "developer3@example.com",
      location: "City 3, Country 1",
      position: "COO",
      images: image17,
      description : 'I am excited to discuss the operational impact of our new eLearning web application. As the Chief Operating Officer, I want to emphasize how this platform aligns with our operational goals and contributes to the overall efficiency and effectiveness of our organization.',
    },
  ];

  const [activeRow, setActiveRow] = useState(null);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isDeveloperDetailsOpen, setIsDeveloperDetailsOpen] = useState(false);
  const [activeDeveloper, setActiveDeveloper] = useState(null);

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Adding and removing resize event listener on component mount/unmount

  const getChunkSize = () => {
    // Adjust the chunk size based on window width
    if (windowSize <= 450) {
      return 1;
    } else if (windowSize <= 540) {
      return 2;
    } else {
      return 3;
    }
  };

  const chunkedDevelopers = chunkArray(developers, getChunkSize());

  const toggleRow = (rowIndex) => {
    // Close the previous developer details card
    if (activeDeveloper && isDeveloperDetailsOpen) {
      setIsDeveloperDetailsOpen(false);
    }

    // Open/close the details card based on the current state
    setIsDeveloperDetailsOpen((prev) =>
      prev && activeRow === rowIndex ? false : true
    );
    setActiveRow((prev) => (prev === rowIndex ? null : rowIndex));
    setActiveDeveloper(null); // Reset active developer when toggling rows
  };

  const selectDeveloper = (developer) => {
    setActiveDeveloper(developer);
  };

  return (
    <div>
         
      <div className="About-container">
        <Nav></Nav>
      {/* <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div> */}
        <div className="About-top-contain">
     
          <div className="About-left">
            <h1>ABOUT US</h1>
            <p>Connecting Minds Through Knowledge</p>
          </div>
          <img src={image} alt="About-logo" />
        </div>
        <div className="About-body">
          <div className="About-body-contain">
            <div className="About-body-contain-1">
              <p>
                INOCYX Learning Platform bridges the gap between aspiring
                Learners and Success, Signifying that Empowerment begins with
                engaging in learner's experience on the learning.
              </p>
            </div>
            <div className="About-body-contain-2">
              <div className="About-body-details">
                <h1>UNLEASING OUR INOCYX TECH MAESTROS</h1>
                <p>
                  Our extraodinare developers with their innate ability to
                  conjure possibilities in every byte, they transform complex
                  data into actionable insights, craft visually stunning
                  interfaces, and build virtual worlds that challenge the
                  boundaries of reality.
                </p>
              </div>
              <div className="About-developer-contain">
                <div>
                  {chunkedDevelopers.map((row, rowIndex) => (
                    <div key={rowIndex} className="developer-row">
                      <div className="user-cards">
                        {row.map((developer) => (
                          <div
                            key={developer.id}
                            className={`developer-item ${
                              activeDeveloper === developer ? "active" : ""
                            }`}
                            onClick={() => selectDeveloper(developer)}
                          >
                            <img
                              src={developer.images}
                              alt={`developer-${developer.id}`}
                              className="spring"
                            />
                            <h1>{developer.name}</h1>
                            <div className="developer-dropdown">
                              <img
                                src={activeRow === rowIndex ? image2 : image2}
                                alt="dropdown-icon"
                                onClick={() => toggleRow(rowIndex)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="user-details">
                        {activeRow === rowIndex ? (
                          <div
                            className={`developer-container ${
                              activeRow === rowIndex ? "open delayed-slide" : ""
                            }`}
                          >
                            {activeDeveloper && isDeveloperDetailsOpen ? (
                              <div className="about-container">
                                <div className="about-container-top-1">
                                  <div className="about-they">
                                    <h1>{activeDeveloper.name}</h1>
                                    <img
                                      src={img3}
                                      alt="logo"
                                      className="aboutus-they-logo"
                                      onClick={() => toggleRow(true)}
                                      style={{
                                        cursor: "pointer",
                                        width: "100",
                                        height: "100",
                                      }}
                                    />
                                  </div>
                                  <div className="they-details">
                                    <div className="they-details-right">
                                      <div className="user-stone">
                                        <div className="stone"></div>
                                        <div className="user-name">
                                          <h1>{activeDeveloper.position}</h1>
                                          <p>At Inocyx</p>
                                        </div>
                                      </div>
                                      <div className="they-right-1">
                                        <img
                                          src={img4}
                                          alt="logo"
                                          className="theyright-aboutus"
                                        />
                                        <p>
                                         {activeDeveloper.description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="about-container-top2">
                                  <div className="about-container-top2-left">
                                    <div className="about-container-top2-left-1">
                                      <img src={img5} alt="note" />
                                  <div>
                                    <h1>
"Explore our dynamic website for a seamless blend of innovation, information, and user-friendly design, tailored to enhance your online experience."</h1>
                                  </div>
                                    </div>
                                    <img
                                      src={activeDeveloper.images}
                                      alt="img"
                                      className="management-img"
                                    />
                                    <div className="social-icons">
                                      <h1>Follow me</h1>
                                      <span>
                                        <img src={insta} alt="insta" />
                                        <img src={linked} alt="linked" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              `Hello-${rowIndex + 1}`
                            )}
                          </div>
                        ) : (
                          <div
                            className={`developer-container close ${
                              activeRow === rowIndex
                                ? "close delayed-slide"
                                : ""
                            }`}
                          >
                            {activeDeveloper && isDeveloperDetailsOpen && (
                              <div className="about-container">
                                <div className="about-container-top-1">
                                  <div className="about-they">
                                    <h1>{activeDeveloper.name}</h1>
                                    <img
                                      src={img3}
                                      alt="logo"
                                      className="aboutus-they-logo"
                                      onClick={() => toggleRow(true)}
                                      style={{
                                        cursor: "pointer",
                                        width: "100",
                                        height: "100",
                                      }}
                                    />
                                  </div>
                                  <div className="they-details">
                                    <div className="they-details-right">
                                      <div className="user-stone">
                                        <div className="stone"></div>
                                        <div className="user-name">
                                          <h1>{activeDeveloper.position}</h1>
                                          <p>At Inocyx</p>
                                        </div>
                                      </div>
                                      <div className="they-right-1">
                                        <img
                                          src={img4}
                                          alt="logo"
                                          className="theyright-aboutus"
                                        />
                                        <p>
                                         {activeDeveloper.description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="about-container-top2">
                                  <div className="about-container-top2-left">
                                    <div className="about-container-top2-left-1">
                                      <img src={img5} alt="note" />
                                  <div>
                                    <h1>
"Explore our dynamic website for a seamless blend of innovation, information, and user-friendly design, tailored to enhance your online experience."</h1>
                                  </div>
                                    </div>
                                    <img
                                      src={activeDeveloper.images}
                                      alt="img"
                                      className="management-img"
                                    />
                                    <div className="social-icons">
                                      <h1>Follow me</h1>
                                      <span>
                                        <img src={insta} alt="insta" />
                                        <img src={linked} alt="linked" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="About-btm-contain">
          <div className="About-btm-details">
            <h1>HOW WE GROW !</h1>
            <p>
              {" "}
              Growing up in a Small Tamilian Village, Inocyx Founder Yusuf
              Rahman and Mohan Babu had few Trading Opportunites untill they got
              a Crypto Platform. Fueled by their dream to Compete in Blockchain.
              They used the Service to Learn their way to a Exchange as
              Innovative Cryptocurrency Exchange as INOCYX. After Exchange they
              developed their Business Life into NFT Marketplace, E-Learning,
              and Metaverse.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
