import React  from 'react';
import "./Home.css";
import { Link } from 'react-router-dom';
import image1 from "../Image/bg (1).png";
import image2 from "../Image/Arrow1.png";
import CardSection from ".././CardSection/CardSection"
import CardSection02 from "../CardSection/Cardsection02"
import Proffesnal from '../CardSection/Proffesnal';
import GrabSection from '../Component/GrabSection/GrabSection';
import Treatise from './Treatise';
import Feedback from "../Component/PlatForm/Feedback"
import Footer from "../Footer/Footer"
import Premium from "../Component/GrabSection/Premium"
import Nav from '../Nav/NavBar';

function Home() {
const email =localStorage.getItem('email');
  return (
  <div id='HOME'>
         <Nav/>
    <div className='plans-container'>
       <div className='home-content'>
        <p className='home-title'>
        Learn Faction Of Concepts For <span className='radid'> Redid Universe</span>
        </p>
        <p className='home-title-text'> Elevate yourself towards your personal and professional aspirations with INOCYX Learning and ignite your journey to success.</p>
       
         {email ? 
          <Link to= "/Articles">
         <div className='btn'>
        <button className='home-button'>Venture into <img src={image2} alt='arrow' className='Arrow-for-home'></img> </button>
        </div>
        </Link> :
           <Link to='/Articles'><div className='btn'>
           <button className='home-button'>Venture into <img src={image2} alt='arrow' className='Arrow-for-home'></img> </button>
           </div>
           </Link>
        }
        
 
       </div>
            <div className='home-image'>
              <img src={image1} className='home-bg-image' alt='efg'></img>
            </div>
     

        </div>
        <GrabSection/>
        <CardSection02/>
        <Proffesnal/>
        <CardSection/>
        <Treatise/>
        <Premium/>
        {/* <Trinity/> */}
        <Feedback/>
        <Footer/> 
     
    
  
        
        </div>
  );

}

export default Home ;
