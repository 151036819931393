import React, { useState, useEffect } from "react";
import "./Faqquestions.css";
import axios from "axios";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function Faq() {
  const [click, setClick] = useState(null);
  const [datavalue, setdatavalue] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://elearning-sand.vercel.app/get-selected-FAQ");
        const dataitems = response.data.data || [];
        setdatavalue(dataitems);
        setLoading(false);
      } catch (error) {
        console.log("Error getting selected FAQ", error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  const toggle = (index) => {
    if (click === index) {
      return setClick(null);
    }
    setClick(index);
  };

  return (
    <div>
      <div className="mid-heading">
        <h1>FAQS</h1>
        <h3>Frequently Asked Questions</h3>
      </div>
      <section className="faq">
        <div className="container-2">
          {loading ? (
            <Stack spacing={4}>
              <Skeleton variant="rectangular" width={990} height={80} />
              <Skeleton variant="rectangular" width={990} height={80} />
              <Skeleton variant="rectangular" width={990} height={80} />
              <Skeleton variant="rectangular" width={990} height={80} />
              <Skeleton variant="rectangular" width={990} height={80} />
              <Skeleton variant="rectangular" width={990} height={80} />
            </Stack>
          ) : (
            datavalue.map((faqItem, index) => (
              <div className="box-faqbox" key={faqItem._id}>
                <button className="accordion" onClick={() => toggle(index)}>
                  <h2 className="box-faqbox">{faqItem.question}</h2>
                  <span>
                    {click === index ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-right"></i>}
                  </span>
                </button>
                {click === index ? (
                  <div className="text-faqus">
                    <h3 className="sub-title-faq">{faqItem.answer}</h3>
                  </div>
                ) : null}
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
}

export default Faq;
