import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { BASE_URL } from '../../Constants';
import { TextField } from '@mui/material';

import "./Forget.css";

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(BASE_URL + 'user-forgetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setIsEmailSent(true); // Set email sent status to true
        setOpen(true); // Show the backdrop
      } else {

        const invaildButton ="invaild email Address"
        console.log(invaildButton)
        window.alert('invaild email Address');
      }
    } catch{
      console.log('invaild email Address');
      window.alert('invaild email Address');
    }
  };

  const handleResetLinkButton = async () => {
    try {
      const response = await fetch(BASE_URL + 'user-forget-resend-link', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        console.log('Reset link sent successfully!');
        window.alert('Reset link sent successfully!');
      } else {
        const invaildButton ="An error occurred while sending the reset link"
        console.log(invaildButton)
        window.alert('An error occurred while sending the reset link');
      }
    } catch (error) {
      console.log('An error occurred while sending the reset link');
      window.alert('An error occurred while sending the reset link');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location.search.includes('token')) {
      const token = getTokenFromURL();
      navigate(`/reset-password?token=${token}`);
    }
  }, [location.search, navigate]);

  const getTokenFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get('token');
  };

  useEffect(() => {
    let timer;
    if (isEmailSent) {
      timer = setTimeout(() => {
        setIsEmailSent(false);
      }, 900000); // 15 minutes in milliseconds
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isEmailSent]);

  return (
    <form onSubmit={handleSubmit} className='Reset-main'>
      <div className='forget-heading-2'><h2>Forget Password</h2></div>
      <div className='reset-input'>
        <h2 className='forget-heading'>Forget Password?</h2>
        <p className='reset-p'>Enter your registered email ID to send a reset password link</p>
        <TextField
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          required
          variant='standard'
         
          sx={{ width: "364.35px", marginTop: "2rem" }}
        />
        <div>
          <button className='reset-btn' type="submit">Send link to email</button>
          
          <p className="content-reset">Not registered yet? <Link to="/SignUp">Sign up now!</Link> </p>
          <div className="signin-bottom-content">
            <div className="signin-bottom-p" ><p>Follow us:</p></div>
            {/* <div className="signin-icons">
              <img src={linkedin} alt="linkedin" width={"17px"} height={"17px"} />
              <img src={facebook} alt="facebook" width={"17px"} height={"17px"} />
              <img src={insta} alt="instagram" width={"17px"} height={"17px"} />
              <img src={twitter} alt="twitter" width={"17px"} height={"17px"} />
            </div> */}
          </div>
        </div>
      </div>
      {isEmailSent ? (
        <Backdrop open={open}>
          <div className='popup-main'>
            <div className='popups-container'>
              <div className='popup-header'>
                <p>Email sent successfully! Go check your email. The reset link will expire after 15 minutes.</p>
              </div>
              <div className='popup-btn1'>
                <p>Didn't receive the link?</p>
                <button className='popup-button1' onClick={handleResetLinkButton}>
                  Send again
                </button>
              </div>
              <div className='popup-btn2'>
                <Link to='/SignIn'>
                  <button className='popup-button2' onClick={handleClose}>
                    Done!
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Backdrop>
      ) : (
        <Fragment />
      )}
    </form>
  );
}

export default ResetPassword;
