import React from 'react'
import useScrollToTop from '../../../Hooks/useScrollToTop';
import Footer from '../../../Footer/Footer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
     <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>EMAIL, DISCUSSION AND TECHNICAL HELP POLICIES
</h1>
   <h5>E-Mail Policy</h5>
   <p> Our INOCYX learning instructors routinely report being overwhelmed by the need 
to answer numerous e-mails they receive from students, especially in totally online 
courses having no face-to-face interaction with students. If you allow e-mail to 
monopolize your time, it will be very difficult to manage your other course 
responsibilities. </p>
<p>Posting an e-mail policy that limits your e-mail workload is therefore a good idea. 
Consider the following three approaches to minimizing the workload e-mail imposes 
on you. Electronic discussions constitute another alternative to e-mail by providing 
opportunities for students to answer each other’s questions.</p>
<p> An ongoing forum for students allows them to post and respond to questions about 
tests, deadlines, or any other related course information. It is likely that a few students 
will emerge as mentors for other students. Most classes contain one or more 
individuals who, given such an opportunity, will act as unappointed helpers of others 
in the class.</p>
<h5>Discussion Policy</h5>
<p> INOCYX learning Platform use the term discussion to denote both chats and 
forums. A discussion policy contains guidelines governing your students’ participation 
in chats and forums, including the degree to which you yourself will enter student 
discussions.

</p>

<p> For example, some instructors participate in a discussion only after all students 
have had a chance to respond. If you use this approach, state this explicitly, so 
students will not be expecting more frequent participation from you. f student 
participation in electronic discussions is required, this should also be clearly stated in 
your discussion policy. </p>
<p>Furthermore, if you are planning to grade students on their participation in 
discussions, you should clearly indicate what grading criteria apply in posted 
instructions for that discussion.</p>
<h5>Technical Help Policy</h5>
<p>INOCYX learning Platform have all experienced the frustrations of having technical 
difficulties with computers that we could not resolve without assistance. For INOCYX 
learning students, this kind of frustration is often compounded, especially when they 
are working under the pressure of assignment deadlines.
 </p>
<p> Therefore, it is imperative that you have a policy explaining how a student can 
receive help for technical difficulties. Your technical help policy should also specify 
how you will contact your students in case technical problems arise that cannot be 
solved expeditiously. Besides your students' e-mail addresses, you probably should know their work and/or home phone telephone numbers and their fax numbers. You 
may gather this data and related information—preferably at the outset of the course—
by having students complete a survey form posted on the course site. </p>
<p> Finally, your technical help policy should specify what you plan to do if technical 
difficulties arise when an online test is scheduled</p>
<p>If you need further clarification on this Disclaimer or have any questions, feel free 
to contact us through the following channels:</p>
<ul>
    <li><p> You can visit <a href='https://www.inocyxlearning.com/' style={{textDecoration: "underline"}}> www.inocyxlearning.com </a> and access the "Contact Us" page for 
more information.</p></li>
    <li><p> Alternatively, you can send an email to <a href='support@inocyxlearning.com' style={{textDecoration: "underline"}}>support@inocyxlearning.com</a>, and our 
team will be happy to assist you.</p></li>
</ul>
  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
     <Footer/>
    </div>
    </div>
  )
}

export default PrivacyPolicy