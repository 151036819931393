import React, { useState, useEffect } from 'react';
import world from "../../../Images/Group 5491.png";
import doller from "../../../Images/Vector (1).png";
import trinity1 from "../../../Images/Frame 11.png";
import check from "../../../Images/Check.png";
import chorno from "../../../Images/Chronometer.png";
import idea from "../../../Images/Idea.png";
import media from "../../../Images/Play.png";
import loupe from "../../../Images/Loupe.png";
import { Link } from 'react-router-dom';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import './Trinity.css';

function TrinityPage() {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    fetch('https://e-learning-backend-seven.vercel.app/get-all-plans')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // const filteredPlans = data.filter((plan) => {
        //   return (
        //     plan.name === 'Beginner' ||
        //     plan.name === 'Intermediate' ||
        //     plan.name === 'Expert'
        //   );
        // });
        setPlans(data.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleAccessClick = (planId) => {
    console.log(`Clicked "Access" for plan with ID: ${planId}`);
  };
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Retrieve email from local storage
    const emailFromLocal = localStorage.getItem("email");

    if (emailFromLocal) {
      setEmail(emailFromLocal);
    }
  }, []);
  const storedProfilePic = localStorage.getItem("profilePic");
  return (
    <div className='Trinity-container'>
      <div className='trinity-nav'>
        <Link to="/"> <div className='back-arrow-02'>
          <ArrowCircleLeftOutlinedIcon sx={{width: "2em", fontSize: "3em"}}/>
        </div></Link>
       
      {email ? (
            <li className='avator-logo' style={{listStyle: "none"}}>
              <Link to="/userprofile">
                <img src={storedProfilePic} alt='ProfileImage' width="50px" height="50px"  className="profile-image-nav" />
              </Link>
            </li>
          ) : (
            <a href='/SignIn' style={{color: "white"}}>        
              <div className='avator'>
                <div className="signIn-btn">
                  <div className='ul-2-li'>
                    <p>SignIn</p>
                  </div>
                </div>
              </div>
            </a>
          )}
      </div>
      <div className='trinity-body'>
        <div className='trinity-top-container'>
          <img src={world} alt='' className='world-1' />
          <h1>Trinity Passage Quest</h1>
          <img src={doller} alt='' className='doller-1' />
        </div>

        <div className='body-container-1'>
          {plans.map((plan) => (
            <div className='contein' key={plan._id}>
              <div className='contein-1'>
                <div className='contein-pora'>
                  <img src={check} alt='chorno' />
                  <p>You enrolled this plan</p>
                </div>
                <div className='left&right'>
                  <div className='contein-left'>
                    <h1>{plan.name} Passage</h1>
                    <img src={trinity1} alt='' className='vector' />
                  </div>
                  <div className='contein-right'>
                    <div className='contein-right-title'>
                      <h1>Forge ahead with blockchain</h1>
                    </div>
                    <div className='contein-right-des'>
                      <div className='contein-right-des1'>
                        <img src={chorno} alt='' />
                        <p>Learn First Steps Curriculum</p>
                      </div>
                      <div className='contein-right-des2'>
                        <img src={idea} alt='' />
                        <p>Complete every video</p>
                      </div>
                      <div className='contein-right-des3'>
                        <img src={loupe} alt='' />
                        <p>10 videos in {plan.name} Plan</p>
                      </div>
                      <div className='contein-right-des4'>
                        <img src={media} alt='' />
                        <p>Enroll Today and Innovate!</p>
                      </div>
                      <div className='contein-right-des5'>
                      <Link to={`/PlansDetails/${plan._id}`}>
  {`Access ${plan.name}`}
</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TrinityPage;
