import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Treatise from "./Pages/Treatise";
import About from "./Pages/About";
import Home from "./Pages/Home";
import Gethelp from "./Pages/GetHelp/Gethelp";
import Raiseticket from "./Pages/GetHelp/RaisedTickets/Raiseticket";
import Viewtickets from "./Pages/GetHelp/Viewtickets";
import PlansDetails from "./Component/CourseDetails/PlansDetails/PlansDetails";
import CrashCourse from "./Component/CourseDetails/CrashCourse/CrashCourse";
import MainComponent from "./SignUp&SignIn/MainComponent";
import Crashvideo from "./Pages/Contact/Crashvideo";
import Feedbackhelp from "./Pages/GetHelp/Feedbackhelp";
import Articles from "./Pages/Articles/Articles";
import Contactus from "./Pages/Contact/Contactus";
import Jargons from "./Pages/Contact/Jargons";
import PaymentPolicies from "./Pages/Policies/Paymentpolicies/PaymentPolicy";
import IntellecualPolicy from "./Pages/Policies/Intellectual/IntellectualPolicy";
import ForgetPassword from "../src/SignUp&SignIn/ForgetPassword/Forget";
import Editprofile from "./Pages/Profiles/EditProfile/Editprofile";
import UserProfile from "./Pages/Profiles/UserProfile/UserProfile";
import TrinityPage from "./Component/CourseDetails/TrinityPage/Trinity";
import ResetPassword from "../src/SignUp&SignIn/ResetPassword/ResetPassword";
import Videos from "./Pages/Contact/Videos";
import PrivacyPolicy from "./Pages/Policies/Privacy&Policy/Privacy&Policy";
import ReportPolicy from "./Pages/Policies/ReportPolicy/ReportPolicy";
import TermConditions from "./Pages/Policies/Term&Condition/Term&Condition";
import Guidelines from "./Pages/Policies/Guidelines/Guidelines";
import LearningPolicy from "./Pages/Policies/LearningPolicy/LearningPolicy";
import Disclaimer from "./Pages/Policies/Disclaimer/Disclaimer";
import StudentPolicy from "./Pages/Policies/StudentPolicy/StudentPolicy";
import Technicalpolicy from "./Pages/Policies/TechnicalPolicy/TechnicalPolicy";
import GetArticle from "./Pages/Articles/SingleArticle/GetArticle";
import Reward from "./Pages/Policies/Reward";
import Refund from "./Pages/Policies/Refund";
import Faq from "./Pages/GetHelp/Faq";
import Test from "./Component/CourseDetails/QuizTest/Test";
import Certificate from "./Component/CourseDetails/Certificate/Certificate"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Treatise" element={<Treatise />}></Route>
        <Route path="/About" element={<About />}></Route>
        <Route path="/gethelp" element={<Gethelp />}></Route>
        <Route path="/Jargons" element={<Jargons />}></Route>
        <Route path="/Raiseticket" element={<Raiseticket />}></Route>
        <Route path="/FAQ" element={<Faq />}></Route>
        <Route path="/Viewtickets" element={<Viewtickets />}></Route>
        <Route path="/Articles" element={<Articles />}></Route>
        <Route
          path="/CrashCourse/:crashCourseId"
          element={<CrashCourse />}
        ></Route>
        <Route path="/SignIn" element={<MainComponent />}></Route>
        <Route path="/plansDetails/:id" element={<PlansDetails />} />
        <Route path="/Feedbackhelp" element={<Feedbackhelp />}></Route>
        <Route path="/contactus" element={<Contactus />}></Route>
        <Route path="/Editprofile/:_id" element={<Editprofile />}></Route>
        <Route path="/Trinity" element={<TrinityPage />}></Route>
        <Route path="/Intellectual" element={<IntellecualPolicy />}></Route>
        <Route
          path="/get-videosBy-planId/:crashCourseId"
          element={<Crashvideo />}
        />
        <Route path="/ForgetPassword" element={<ForgetPassword />}></Route>
        <Route path="/user-resetpassword/:token" element={<ResetPassword />} />
        <Route path="/userprofile" element={<UserProfile />}></Route>
        <Route path="/Videos/:_id" element={<Videos />}></Route>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/PaymentPolicy" element={<PaymentPolicies />}></Route>
        <Route path="/ReportPolicy" element={<ReportPolicy />}></Route>
        <Route path="/Term&Condition" element={<TermConditions />}></Route>
        <Route path="/Guidelines" element={<Guidelines />}></Route>
        <Route path="/Disclaimer" element={<Disclaimer />}></Route>
        <Route path="/LearningPolicy" element={<LearningPolicy />}></Route>
        <Route path="/StudentPolicy" element={<StudentPolicy />}></Route>
        <Route path="/TechnicalPolicy" element={<Technicalpolicy />}></Route>
        <Route path="/get-treatise/:_id" element={<GetArticle />}></Route>
        <Route path="/Reward" element={<Reward />}></Route>
        <Route path="/Refund" element={<Refund />}></Route>
        <Route path="/test/:id" element={<Test/>}></Route>
        <Route path="/certificate/:certificateID" element={<Certificate/>}></Route>

      </Routes>
    </div>
  );
}
export default App;
