import React, { useState, useEffect } from "react";
import { BASE_URL } from "../Constants";
import * as Components from "./Components";
import cancel from "../Images/Cancel.png";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import "./MainComponent.css";
import { Google } from "@mui/icons-material";
import SignInMain from "./SignIn/SignInMain";
import { color } from "framer-motion";

function MainComponent() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signIn, toggle] = React.useState({});
  const [openModal, setOpenModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [resendTimer, setResendTimer] = useState(120);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch(BASE_URL + "user-signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ firstName, lastName, email, password }),
    });
    if (response.ok) {
      setOpenModal(true);
    } else if (!response.ok) {
      console.log("Email Already Exists");
      toast.error("You are already created the account. Please login.");
    } else {
      console.log("somthing is not missing");
      toast.error("Registration failed. Please try again.");
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch(BASE_URL + "user-signupotp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, otp }),
    });
    if (response.ok) {
      setIsVerified(true);

      setTimeout(() => {
        toggle(true);
      }, 1000);
      toast.success("OTP verification successful. You are now logged in.");
    } else {
      toast.error("Invalid OTP entered.enter the correct one.");
      console.log("OTP was not sent. Please try again.");
    }
  };

  const handleDone = () => {
    setOpenModal(false);
    window.location.href = "/SignIn";
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleResendOTP = async () => {
    const response = await fetch(BASE_URL + "user-resend-otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      setResendTimer(120);
    } else {
      toast.error("Resent OTP was not sent. Please try again.");
      console.log("Resent OTP was not sent. Please try again.");
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (resendTimer === 0) {
      clearInterval();
    }
  }, [resendTimer]);

  return (
    <div className="signin-main">
      <Components.Container>
        <Components.SignInContainer signinIn={signIn}>
          <SignInMain />
        </Components.SignInContainer>
        <Components.SignUpContainer signinIn={signIn}>
          <Components.Form onSubmit={handleSubmit}>
            <Components.Title>Create Account</Components.Title>
            <Components.Input
              type="text"
              placeholder=" First Name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
            <Components.Input
              type="text"
              placeholder=" Last Name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              required
            />
            <Components.Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <Components.Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
            <Components.Button>Create account</Components.Button>
          </Components.Form>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {isVerified ? (
              <div className="otp-verification-container">
                <div className="otp-verification">
                  <p>Email verified successfully & sign up completed!</p>
                  <ThumbUpIcon sx={{ color: "#00bfff" }} />
                  <button
                    style={{
                      width: "74.41px",
                      height: "28.76px",
                      backgroundColor: "#FFD85B",
                      border: "none",
                      borderRadius: "4.37716px",
                      fontSize: "12.5062px",
                    }}
                    onClick={handleCloseModal}
                  >
                    Done!
                  </button>
                </div>
              </div>
            ) : (
              <form onSubmit={handleOtpSubmit}>
                <div className="otp-verification-container">
                  <div className="otp-verification">
                    <div className="cancel-img">
                      <img src={cancel} alt="" onClick={handleCloseModal} />
                    </div>
                    <p>
                      Please enter the OTP sent to your email ID to verify your
                      email
                    </p>
                    <TextField
                      value={otp}
                      onChange={(event) => setOtp(event.target.value)}
                      InputProps={{
                        style: { color: "white" },
                      }}
                    />
                    <span>4 Digits</span>
                    <div className="otp-box">
                      <div className="btn-container">
                        <button
                          className="otp-button"
                          type="submit"
                          value="submit"
                        >
                          Get Verified
                        </button>
                      </div>
                    </div>
                    {resendTimer > 0 ? (
                      <div className="otp-countdown">
                        OTP will expire in {resendTimer} seconds
                      </div>
                    ) : (
                      <div className="resent-otp">
                        <div className="resent">Resend OTP in</div>
                        <div className="sen-again" onClick={handleResendOTP}>
                          Send again
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            )}
          </Modal>
        </Components.SignUpContainer>
        <Components.OverlayContainer signinIn={signIn}>
          <Components.Overlay signinIn={signIn}>
            <Components.LeftOverlayPanel signinIn={signIn}>
              <Components.Titles>Greeting Crew!</Components.Titles>
              <Components.Paragraph>
                Open the Gateway to Innovative Learning Opportunities, Propel
                yourself toward personal and professional success through INOCYX
                Learning{" "}
              </Components.Paragraph>
              <Components.GhostButton onClick={() => toggle(true)}>
                LOGIN
              </Components.GhostButton>
            </Components.LeftOverlayPanel>
            <Components.RightOverlayPanel signinIn={signIn}>
              <Components.Titles>Rejoice ! You're Welcome</Components.Titles>
              <Components.Paragraph>
                Login Using correct Credential for Uninterrupted Learning to
                sparking a radiant journey to success{" "}
              </Components.Paragraph>
              <Components.GhostButton onClick={() => toggle(false)}>
                Sigin Up
              </Components.GhostButton>
            </Components.RightOverlayPanel>
          </Components.Overlay>
        </Components.OverlayContainer>
      </Components.Container>
      <ToastContainer />
    </div>
  );
}

export default MainComponent;
