import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import useScrollToTop from '../../../Hooks/useScrollToTop';
import "./ReportPolicy.css"
import Footer from '../../../Footer/Footer';
function ReportPolicy() {
   useScrollToTop();
  return (
    <div className='ReportPolicy'>
     <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='ReportPolicy-mid'>
        <div className='ReportPolicy-main'>
        <h1  style={{fontSize:'4rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>Report Policy</h1>
        <p>At Inocyx, we strive to create a safe and respectful learning environment for all users. This Report Policy outlines the types of behavior that are prohibited on our website and the procedures for reporting violations of our policies.</p>
        <h2>Prohibited Behavior</h2>
        <p>The following types of behavior are prohibited on our learning website:</p>
        <ul>
            <li><p>Harassment or discrimination based on race, ethnicity, gender, sexual orientation, religion, or any other protected characteristic</p></li>
       <li><p>Bullying, intimidation, or threats against other users or our staff</p></li>
       <li><p>Posting or sharing of offensive, obscene, or sexually explicit content</p></li>
       <li><p>Unauthorized access to other users' accounts or our system</p></li>
       <li><p>Sharing of personal information of other users without their consent</p></li>
       <li><p>Spamming or other forms of unsolicited communication</p></li>
       <li><p>Any other behavior that violates our Terms of Use or applicable laws and regulations
Reporting Violations</p></li>
        </ul>
        <p>If you believe that another user has engaged in behavior that violates our policies, please follow these steps to report the behavior:</p>
        <p><strong>Gather evidence:</strong> If possible, collect evidence of the behavior, such as screenshots, chat logs, or other documentation.
Report the behavior: Contact our support team to report the behavior. You can do this by sending an email to [Insert Email Address] or filling out our online contact form [Insert Link to Contact Form]. Please provide as much detail as possible about the behavior, including the date and time it occurred and any relevant evidence.
Review of report: Our support team will review your report and take appropriate action. This may include warning or suspending the offending user, removing offending content, or taking other measures as deemed necessary.
Confidentiality</p>
        <p>We will make every effort to keep the details of your report confidential. However, in some cases, we may need to share the information with law enforcement, legal authorities, or other third parties as required by law.</p>
        <h2>False Reports</h2>
        <p>Making a false report is prohibited and may result in disciplinary action. If you submit a report in good faith but we find no evidence of a violation, we will not take action against the reported user.</p>
     <h2>Conclusion</h2>
<p>We are committed to creating a safe and respectful learning environment for all users of our website. By reporting violations of our policies, you help us maintain this environment and ensure that everyone can learn and grow without fear of harassment or other prohibited behavior. If you have any questions or concerns about our Report Policy, please contact our support team.</p>

        </div>
      
     </div>
     <div className='ReportPolicy-bottom'>
 <Footer/>
 </div>
    </div>
  )
}

export default ReportPolicy