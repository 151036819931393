import React from 'react'
import './Cardsection02.css'
import { Link } from 'react-router-dom'
import  { useEffect, useState } from 'react';
import microimage1 from '../Image/MicrosoftTeams-image (16).png'
import microimage2 from '../Image/MicrosoftTeams-image (11).png'
import microimage3 from '../Image/MicrosoftTeams-image (12).png'
import microimage4 from '../Image/MicrosoftTeams-image (13).png'
import microimage5 from '../Image/MicrosoftTeams-image (14).png'
import microimage6 from '../Image/MicrosoftTeams-image (15).png'
import { ArrowForward } from '@mui/icons-material'
function Responsive() {
  const [crashCourseId, setCrashCourseId] = useState('');

  useEffect(() => {
    fetch('https://e-learning-backend-seven.vercel.app/get-videosBy-planId/6489b8652931b70b2b9d58fb')
      .then(response => response.json())
      .then(data => setCrashCourseId(data))
      .catch(error => console.error('Error:', error));
  }, []);

 console.log(crashCourseId,'crashCourseId')

const planId = crashCourseId && crashCourseId?.data[0]?.planId;
console.log(planId,'planId')

  return (
    <div className='Responsive-container'>
     <div className='Explore-title'>
      <h1>KNOWLEDGE  ROCKETS</h1>
      <p>Enticing learner to enroll in the  featured crash course and discover the Wonders of Rapid Knowledge Acquisition. Master the Arts of Blockchain . Enroll Today and Lead Tomorrow..</p>
     </div>
<div className='Explore-crash'>
<div className='crashcpurse-btn'>
        <Link  to={`/CrashCourse/${planId}`}>
          <button className='crash' >
           <h1>Crash Course</h1>
            <ArrowForward sx={{ color: 'white' }} />
          </button>
        </Link>
      </div>
     <div className='Explore-crashcontainer'>
     {crashCourseId?.data?.map((item)=>(
       <div className='container-box1 box'>
       {/* <div className='timing'><p>Experied 12 days</p></div> */}
       <div className='box1-img'>
       <img src={microimage1} alt='micro' width={100} height={100} />
       <h2>{item.title}</h2>
       </div>
       <div className='box1-btm'>
       <div className='box1-keywords'>
           <p>#Block Chain Technology</p><strong>|</strong>
          
           <p>#web3</p>
       </div>
       <div className='box1-price'>
       <p>INR {item.price}</p> <strong>/</strong>
       <span>month</span>
       </div>
       {/* <Link  to={`/get-videosBy-planId/${item._id}`}>   <div className='box1-btn'>
        <p>GetCourse</p>
       </div></Link> */}
        <Link  to={`/CrashCourse/${planId}`}>   <div className='box1-btn'>
        <p>GetCourse</p>
       </div></Link>
       </div>
     </div>
     ))}
      </div>
     </div>
    
    </div>
  )
}

export default Responsive