
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
 import { CloudinaryContext } from 'cloudinary-react';
import DeleteIcon from '@mui/icons-material/Delete';
import "./Editprofile.css"
import image20 from "../../../Image/image 20.png"
import Image from "../../../Image/Ellipse 56.png"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const BASE_URL = 'https://e-learning-backend-seven.vercel.app/';


export default function UserProfile() {
  const { _id } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    profileURL: '',
    firstName: '',
    lastName: '',
    currentPassword: '',
    newPassword: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const checkToken = localStorage.getItem('token');

        if (checkToken) {
          const url = BASE_URL + 'dashboard-profile';
          const bearer = 'Bearer ' + checkToken;
          const emailFromLocal = localStorage.getItem('email');

          if (emailFromLocal) {
            const response = await fetch(url, {
              method: 'POST',
              body: JSON.stringify({ email: emailFromLocal }),
              headers: {
                'Content-Type': 'application/json',
                Authorization: bearer,
              },
            });

            if (response.ok) {
              const data = await response.json();
              setUser(data?.data);
              setFormData((prevFormData) => ({
                ...prevFormData,
                profileURL: data?.data[0].profileURL || '',
                firstName: data?.data[0].firstName || '',
                lastName: data?.data[0].lastName || '',
                currentPassword:data?.data[0].currentPassword || ''
              }));
              
            } else {
              throw new Error('Failed to fetch user profile data');
          
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(true);

  const handleFormChange = (event) => {
    const { name, value, files } = event.target;

    if (name === 'profileURL') {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          profileURL: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

      if (name === 'currentPassword') {
        const isValid = checkCurrentPasswordValidity(value);
        setIsCurrentPasswordValid(isValid);
      }

      const checkCurrentPasswordValidity = (enteredCurrentPassword) => {
        return enteredCurrentPassword === user.currentPassword;
      };
    }
  };
  
  const handleProfileSubmit = async (event) => {
    event.preventDefault();


    try {

      const checkToken = localStorage.getItem('token');

      if (checkToken) {
        setIsSubmitting(true);
        const url = BASE_URL + _id;
        const bearer = 'Bearer ' + checkToken;

        if (formData.profileURL instanceof File) {
          const formDataCloudinary = new FormData();
          formDataCloudinary.append('file', formData.profileURL);
          formDataCloudinary.append('upload_preset', uploadPreset);

          const uploadResponse = await fetch(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            {
              method: 'POST',
              body: formDataCloudinary,
            }
          );

          if (uploadResponse.ok) {
            const uploadData = await uploadResponse.json();
            const profileURL = uploadData.secure_url;

            setFormData((prevFormData) => ({
              ...prevFormData,
              profileURL,
            }));
          } else {
            throw new Error('Failed to upload profile picture to Cloudinary');
          }
        }

        const { currentPassword, newPassword, ...profileData } = formData;

        const requestBody = {
          ...profileData,
          currentPassword: currentPassword,
          newPassword: newPassword || currentPassword,
        };

        const response = await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
            Authorization: bearer,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data?.data);
          alert('Profile updated successfully');
          navigate('/userprofile');
        } else {
          throw new Error('Failed to update profile');
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageClick = () => {
    document.getElementById('profileImageInput').click();
  };

  const handleImageDeletion = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      profileURL: '', 
    }));
  };

  const uploadPreset = process.env.UPLOAD_PRESET;
  const cloudName = process.env.CLOUD_NAME;
  return (
   
      <div className="edit-top">
        <div className='back-arrow'>
          <div className='arrow-left'>
              <Link to="/userprofile" className='a-tag'>
              <div className='back-to-home'>
             <KeyboardBackspaceIcon/>
              Back to Profile
              </div>
              </Link>
           
          </div>
          <div className='arrow-mid'>
           Edit Profile
          </div>
          <div className='arrow-right'>
            <h3>Logout</h3>
          </div>
        </div>
       
        
          {user && (
            <div className='edit-name-photo'>
   <CloudinaryContext cloudName="djceg0ozc" className="edit-form">
   <img
     src={formData.profileURL || user.profileURL || {Image}}
     alt="Profile"
     width="150"
     height="150"
     style={{ borderRadius: '50%', cursor: 'pointer' }}
     onClick={handleImageClick}
   />
   <p style={{padding:'1em 0.5rem',fontFamily:'Poppins'}}>must be upload 60kb photo </p>
 </CloudinaryContext>
 <div className='edit-name-photo-left'>

 <div className='profile-selected'>
 <input
   id="profileImageInput"
   type="file"
   name="profileURL"
   onChange={handleFormChange}
   style={{ display: 'none' }}
 />
</div>
<div className='select-delet'>
    <button onClick={handleImageClick} className='select-btn'>Select Image</button>
    <button onClick={handleImageDeletion} className='delete-btn'>Delete Image <DeleteIcon sx={{color:'#622E27'}}/></button>
  </div>
</div>
<div className='edit-name-photo-right'>
    <img src={image20}  alt='' width={100} height={200}/>
 Only profile photo with the following extensions are allowed jpg and jpeg
   </div> 
 </div>
 
)}
      {user && (
          <div className='edit-name'>
            <form onSubmit={handleProfileSubmit} className="form-1">
            
            <label>
            
              <input
               hidden
                type="file"
                name="profileURL"
                onChange={handleFormChange}
              />
            </label>
            <div className="form-container-1">
              <div className='form-name'>
           <div className='first-Name'>
           First Name
           </div>
           <div>
              <input
               className='input-name'
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
              />
             </div>
            </div>
            <div className='form-name'>
            <div className='form-name'>
           <div className='first-Name'>
           last Name
           </div>
           <div >
              <input
                className='input-name'
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
              />
             </div>
            </div>
            </div>
          </div>
        </form>
            <form onSubmit={handleProfileSubmit} className="form-2">
              <div className="form-container-2">
                <div className='password-change'>
                  <p>Change Password</p>
                  <div className='password-input'>
                  Enter Current Password
                  <input
                   className='input-name-1'
                    type="password"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleFormChange}
                  />
                  </div>
                  {!isCurrentPasswordValid && (
            <p style={{ color: 'red' }}>Invalid current password. Please enter the correct current password.</p>
          )}
                  <div className='password-input'>
                  Enter New Password
                  <input
                    className='input-name-1'
                    type="password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleFormChange}
                  />
                </div>
              
                <div className='alert'>
<div style={{color:'red'}}>*</div>Password should contain numbers,alphabet and symbols
</div>
</div>
<div className='pass-btn-save'>
                <button type="submit" disabled={isSubmitting}  className='password-change-btn'>
                  {isSubmitting ? 'Saving...' : 'Save Changes'}
                </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    
  );
}




