import React, { useEffect, useState } from 'react';
import UsePagination from './TablePagination';
import Menu from './ViewMenu';
import "./Raiseticket.css"

function RaisedTickets() {
  const itemsPerPage = 10;
  const [tickets, setTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Get user email from local storage
    const userEmail = localStorage.getItem('email'); // Replace 'userEmail' with your actual key

    if (userEmail) {
      // Fetch tickets based on the user's email
      const fetchTickets = async () => {
        try {
          const response = await fetch(`https://e-learning-backend-seven.vercel.app/get-ticketByEmail/${userEmail}`);
          const data = await response.json();
          setTickets(data.data); // Assuming the API response is an object with a 'data' property containing an array of tickets
        } catch (error) {
          console.error('Error fetching tickets:', error);
        }
      };

      fetchTickets();
    }
  }, []); // Empty dependency array to ensure the effect runs only once on component mount

  const [user, setUser] = useState(null);

  const fetchUserDetails = async (ticketId) => {
    try {
      const response = await fetch(
        `https://e-learning-backend-seven.vercel.app/get-ticketById/${ticketId}`
      );
      const userDetails = await response.json();
      setUser(userDetails.data);
      console.log(user, "User Details from parent");
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleDrawer = (ticketId) => {
    console.log("Toggle drawer for ticket ID:", ticketId);

    fetchUserDetails(ticketId); // Fetch user details when toggle button is clicked
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedTickets = tickets.slice(startIndex, endIndex);

  return (
    <div>
      <div className='raised-ticket-table'>
        <table className='table-001'>
          <thead className='table-heading'  style={{border: "none"}}>
            <tr className='tr-001'>
              <th className='ticket-table-head' >S.No</th >
              <th className='ticket-table-head'>Ticket Id</th>
              <th className='ticket-table-head'>Reason</th>
              <th className='ticket-table-head'>Category</th>
              <th className='ticket-table-head'>Priority</th>
              <th className='ticket-table-head'>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {displayedTickets.map((ticket, index) => (
              <tr key={ticket._id} className='tr-001' style={{border: "none"}}>
                <td style={{ fontSize: '12px', border: "none" }}>{startIndex + index + 1}</td>
                <td style={{ fontSize: '12px' }}>{ticket.ticketId}</td>
                <td className='ticket-reason'>{ticket.reason}</td>
                <td className='ticket-category'>{ticket.category}</td>
                <td style={{fontSize: "12px "}} >{ticket.priority}</td>
                <td>
                  <span style={{
                    backgroundColor: ticket.status === 'Assigned' ? '#ecfdf3' : '#fff2ea',
                    color: ticket.status === 'Assigned' ? '#128c53' : '#cb3f35',
                    fontSize: '12px'
                  }}>{ticket.status}</span>
                </td>
              
                <td><Menu 
                
                handleToggleDrawer={handleToggleDrawer}
                ticketId={ticket.ticketId}
                user={user}
                />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='pagination-ticket-table'>
        <UsePagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={tickets.length}
          onPageChange={(newPage) => setCurrentPage(newPage)}
        />
      </div>
    </div>
  );
}

export default RaisedTickets;
