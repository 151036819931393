import React from 'react'

import useScrollToTop from '../../../Hooks/useScrollToTop';
import Footer from '../../../Footer/Footer';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
function PrivacyPolicy() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
          <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>GUIDELINES</h1>
    <h5 style={{fontSize:'2rem',marginBottom:'1em'}}>SUBMISSION GUIDELINES</h5>
    <h5 style={{marginBottom:'1em'}}>Students Online/Offline Activities</h5>
    <ul>
        <li><p> Maintain a schedule for sleep, food, noting time spent on the internet for learning 
and time spent on the internet for socializing etc. </p></li>
        <li><p> Besides online learning, every day read from textbooks and read other books as well.</p></li>
        <li><p> As a follow up to online class, explore further through activities, experiments, 
creative expressions etc. </p></li>
        <li><p>Access the digital resources provided through the various national ICT initiatives.</p></li>
        <li><p> Take notes during the online class and review them offline.</p></li>
        <li><p> Restrict time for surfing and regulate screen time for scrolling/reading information 
on every issue (not more than 2 hours for secondary level students) </p></li>
        <li><p> During online activities take small breaks to stand up and move away from the 
screen. During this break, take a quick walk indoors, do few stretch exercises, practice 
deep breathing, catch up with family members etc. Do not continue to sit and surf or 
chat online. </p></li>
        <li><p> Avoid using mobile/internet surfing 40 minutes prior to going to bed because 
engaging in passive communication just before sleeping makes the brain active and 
makes it difficult for the brain and body to relax. </p></li>
    </ul>
    <h5>Safety and Ethics Related Precautions</h5>
    <ul>
        <li><p> Seek parents’ permission before sharing any personal information on the Internet</p></li>
        <li><p> Be careful about cyber bullying and stop yourself from bullying others.</p></li>
        <li><p>Follow netiquette and behave responsibly while online.</p></li>
    </ul>
    <h5>Online Learning of Children with Special Needs (CWSN)</h5>
    <ul>
    <li><p>create/Curate and Share Content.</p></li>
    <li><p> Measures to be taken for CWSN</p></li>
    </ul>
    <h5>TRANSLATION GUIDELINES</h5>
    <p> If you want to make sure your translations are done quickly and efficiently, you 
need to adopt certain best practices as follows.
</p>
<h5>Start with a final and approved version of the course in source 
language:</h5>
<p>It is advisable not to simultaneously work on the source language as well as 
translated version. It saves a lot of reworks and, multiple iterations can be avoided 
when you have a “fixed” final output that needs to be translated into various 
languages</p>
<h5>Reduce the textual elements in images and videos:</h5>
<p>If you have limited textual elements in images and videos, it reduces the 
translation and integration time. Wherever textual elements are unavoidable, it is 
better to have them in layers for different languages to expedite the process. See box to 
learn more about handling images and videos.</p>
<h5>Importance of source files in eLearning translations:
</h5>
<p>E-learning course consists of several audio, video, image and textual elements. 
Each of these elements needs to be translated to ensure a perfect translation. So, if you 
plan to translate courses into multiple languages, even if not immediately, you should 
do the following.
</p>
<ul>
    <li><p> If Flash objects are used in the course, you should have a backup of the source 
files which normally have .fla extension. This saves a lot of time as they need 
not be recreated during translation.</p></li>
    <li><p> Video source files should also be preserved. Textual content that appears in 
front has to be translated into the target languages and having the source file 
makes it easier as one need to work on just the text and publish the videos into 
multiple languages as required.</p></li>
    <li><p> Image or graphic files, which are typically created using software such as 
Photoshop should also be saved. Image files have layers with different elements 
that include textual elements. With source files, it becomes easier to work on 
the layers with textual elements and save the graphics into the desired 
languages.
</p></li>
    <li><p> If you plan to include learning resources in the form of PDF documents in the 
course, you have to make sure that the source files in the .Indd format are 
available for editing and revised based on the target language. This ensures that 
the quality of the output of the translated resource is as good as the original</p></li>
</ul>
<h5>Use translation templates:</h5>
<p> Based on the finalized source language course, you can create templates that 
provide information about what needs to be done in an easy-to-understand manner. 
Similarly, templates will be helpful when sending courses for translation as well as for 
audio narration. They will benefit not only the narrator and translator but also the 
eLearning developer who needs to integrate various elements</p>
<h5>Choose an eLearning authoring tool that provides translation 
options:</h5>
<p>Some authoring tools allow the creation of multiple language versions within a 
single course giving you options to switch between languages. They save time and are 
quite convenient.</p>
<p>E-learning translations can be either a laborious exercise or a smooth process 
depending on the preparation and pre-work that is done in advance. With careful 
planning, translations of eLearning courses can be done easily and in a cost-effective 
manner. All you have to do, is pay attention to the little details that go a long way in 
streamlining the process</p>
  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
    <Footer/>
    </div>
    </div>
  )
}

export default PrivacyPolicy