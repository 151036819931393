import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import world from "../../../Images/Group 5491.png";
import doller from "../../../Images/Vector (1).png";
import check from "../../../Images/Check.png";
import trinity3 from "../../../Images/Rectangle 40.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Footer from "../../../Footer/Footer";
import "./PlansDetails.css";
import Demo from "../../../Demo";
import axios from "axios";
import plans from "../PlansDetails/plans"
import useScrollToTop from "../../../Hooks/useScrollToTop";

function BeginnerPage() {
  const { id , name } = useParams();
  const [videos, setVideos] = useState([]);
  

  useEffect(() => {
    fetch(
      `https://e-learning-backend-seven.vercel.app/get-videosBy-planId/${id}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setVideos(data?.data, "setVideos");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  const [email, setEmail] = useState("");

  useEffect(() => {

    const emailFromLocal = localStorage.getItem("email");

    if (emailFromLocal) {
      setEmail(emailFromLocal);
    }
  }, []);

  const level = videos.length > 0 ? videos[0].level : null;
  console.log(level, "level");
  const storedProfilePic = localStorage.getItem("profilePic");

  const [videoDurations, setVideoDurations] = useState({});

  const handleVideoDurationChange = (videoId, newDuration) => {
    setVideoDurations((prevDurations) => ({
      ...prevDurations,
      [videoId]: newDuration,
    }));
  };

  const formatDurationInMinutes = (durationInSeconds) => {
    if (durationInSeconds === 0) {
      return "00:00";
    }

    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes}:${seconds.toFixed(0).padStart(2, "0")}`;
  };
  console.log(plans , "formatDurationInMinutes")

  const filteredPlans = plans.filter(plan => plan.name === level);
  const planTitles = filteredPlans.map(plan => plan.title);
  const plansdescription = filteredPlans.map(plan => plan.description)
  const plansprice = filteredPlans.map(plan => plan.price)

  const [certificateData , setCertificateData] = useState([]);
 
  
  const filtername = certificateData.filter((item) => item.planName === level);
if (filtername.length > 0) {
  
  const plansname = filtername[0].planName
  console.log(plansname, "filtername");
} else {
  console.log("No item found with the specified plan name");
}


  useEffect(() => {
    const fetchCertificateData = async () => {
      try {
  
        const uid = localStorage.getItem('uid');
        if (!uid) {
  
          console.error('User ID (uid) not found in local storage');
          return;
        }

        const response = await axios.get(
          `https://e-learning-backend-seven.vercel.app/get-certificate-by-uid/${uid}`
        );

        setCertificateData(response.data.data);
      } catch (error) {
        console.error('Error fetching certificate data:', error);
      }
    };

    fetchCertificateData();
  }, []);

  useScrollToTop();
  return (
    <div>
      <div className="Beginner-container">
        <div className="Beginner-nav">
          <Link to="/Trinity">
            {" "}
            <div className="back-arrow-02">
              <ArrowCircleLeftOutlinedIcon
                sx={{ width: "2em", fontSize: "3em" }}
              />
            </div>
          </Link>
          {email ? (
            <Link to="/userprofile">
              <img
                src={
                  storedProfilePic ||
                  "https://cdn3.iconfinder.com/data/icons/business-round-flat-vol-1-1/36/user_account_profile_avatar_person_student_male-512.png"
                }
                alt="ProfileImage"
                width="50px"
                className="profile-image-nav"
                height="50px"
              />
            </Link>
          ) : (
            <a href="/SignIn" style={{ color: "white" }}>
              <div className="avator">
                <div className="signIn-btn">
                  <div className="ul-2-li">
                    <p>SignIn</p>
                  </div>
                </div>
              </div>
            </a>
          )}
        </div>
        <div className="Beginner-mid-container">
          <img src={world} alt="" className="world-2" />
          <h1>{level} Passage</h1>
          <img src={doller} alt="" className="doller-2" />
        </div>
        <div className="Beginner-body-contain">
          <div className="Beginner-body-1">
            <Link to="/Trinity">
              {" "}
              <p>Passages</p>
            </Link>

            <ArrowForwardIosIcon
              sx={{
                fontFamily: "Poppins",
                fontSize: "1rem",
                color: "#C68A6D",
                fontWeight: "400",
              }}
            />
            <span>{level} Passage</span>
          </div>
          <div className="Beginner-body-2">
            <div className="Beginner-pora">
              <img src={check} alt="chorno" />
              <p>You enrolled this plan</p>
            </div>
            <div className="Beginner-body">
              <div className="Beginner-body-left">
                <img src={trinity3} alt="" />
              </div>
              <div className="Beginner-body-right">
                <div className="Beginner-right-title">
                  <h1>{planTitles}</h1>
                  <p>
                   {plansdescription}
                  </p>
                  <p>{level}</p>
                  
                  <div className="Beginner-right-button">
                 
                    {level !== "Crash Course" && 
                     <button className="rgt-btn-01" > {plansprice} 
                      { (level === "Intermediate" || level === "Expert") && ( 
                     <span> INR </span>
                      )}
                      </button>
                    }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
         { level === "Beginner" ? 
         (   <>
          {videos.map((video) => (
         <div className="Beginner-body-3" key={video._id}>
           <div className="Beginner-video-countain">
             <MyVideo
               videoUrl={video.videoUrl}
               onDurationChange={(newDuration) =>
                 handleVideoDurationChange(video._id, newDuration)
               }
               className="video-img"
             />
             <h1>{video.title}</h1>
             <div className="beginner-duration">
               <AccessTimeIcon />
               <p>{formatDurationInMinutes(videoDurations[video._id])}min</p>
             </div>
             <div className="beignner-videoplayer">
         <PlayCircleOutlineIcon />
         {email ? (
           <Link to={`/Videos/${video._id}`}>
             <button>
               <p>Learn Now</p>
             </button>
           </Link>
         ) : (
          
           <a href="/SignIn">
             <button>
               <p>Sign In to Learn</p>
             </button>
           </a>
         )}
       </div>
           </div>
         </div>
       ))}
       </> ) : (
       
          
         <div   >
            <div className="comming"> 
            <h1 className="soon">Coming Soon</h1>
           </div  >
       <div className="commin-zoon" >
         {videos.map((video) => (
         <div className="Beginner-body-scrol"  key={video._id}>
         
           <div className="Beginner-video-countain">
             <MyVideo
               videoUrl={video.videoUrl}
               onDurationChange={(newDuration) =>
                 handleVideoDurationChange(video._id, newDuration)
               }
               className="video-img"
             />
             <h1>{video.title}</h1>
             <div className="beginner-duration">
               <AccessTimeIcon />
               <p>{formatDurationInMinutes(videoDurations[video._id])}min</p>
             </div>
             <div className="beignner-videoplayer">
         <PlayCircleOutlineIcon />
         {email ? (
      
           <Link to={`/Videos/${video._id}`}>
             <button>
               <p>Learn Now</p>
             </button>
           </Link>
         ) : (
 
           <a href="/SignIn">
             <button>
               <p>Sign In to Learn</p>
             </button>
           </a>
         )}
       </div>
           </div>
         
         </div>
          ))}
    </div>
       </div>
   
       )
         } 
          
        </div>
        <div className="textbtn-contain">
  {email ? (
    <>
      {filtername.length > 0 ? (
    
        <>
          {level === filtername[0].planName ? (
           null
          ) : (
            <Link to={`/test/${id}`}>
              <button type="button" className="test-enable">
                Test
              </button>
            </Link>
          )}
        </>
      ) : (
    
        <Link to={`/test/${id}`}>
        <button type="button" className="test-enable">
          Test
        </button>
      </Link>
      )}
    </>
  ) : (

    <a href="/SignIn">
      <button className="test-btn">
        <p>Sign In to test</p>
      </button>
    </a>
  )}
</div>

      </div>
      <Footer />
    </div>
  );
}

const MyVideo = ({ videoUrl, onDurationChange }) => {
  const videoEl = useRef(null);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      const video = videoEl.current;
      if (video) {
        const newDuration = video.duration;
        onDurationChange(newDuration);
      }
    };

    const video = videoEl.current;
    if (video) {
      video.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (video) {
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      }
    };
  }, [onDurationChange]);

  return (
    <div style={{ display: "flex" }}>
      <video src={videoUrl} width="250px" height="250px" ref={videoEl} />
    </div>
  );
};

export default BeginnerPage;
