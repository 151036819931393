import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProfileCertificate.css';
import Footer from '../../../Footer/Footer';
import html2canvas from "html2canvas";
import { Link } from 'react-router-dom';


function ProfileCertificate() {
  const [certificateData, setCertificateData] = useState([]);

  useEffect(() => {
    const fetchCertificateData = async () => {
      try {
  
        const uid = localStorage.getItem('uid');
        if (!uid) {
  
          console.error('User ID (uid) not found in local storage');
          return;
        }

        const response = await axios.get(
          `https://e-learning-backend-seven.vercel.app/get-certificate-by-uid/${uid}`
        );

        setCertificateData(response.data.data);
      } catch (error) {
        console.error('Error fetching certificate data:', error);
      }
    };

    fetchCertificateData();
  }, []);
 
 

  return (
    <div>
      <div className='certificate-Container'>
        <table>
          <thead>
            <tr style={{border:"1px solid #000"}} >
              <th scope="col" style={{ width: '5vw' }} className="purchase-table"></th>
              <th scope="col" style={{ width: '40vw' }} className="purchase-table">Course Name</th>
              <th scope="col" className="purchase-table">Course Type</th>
              <th scope="col" className="purchase-table">Certificate</th>
             
            </tr>
          </thead>
          <tbody>
  {certificateData.length > 0 ? (
    certificateData.map((certificate, index) => (
      <tr key={index}>
        <td data-label="Account">{index + 1}</td>
        <td data-label="Due Date">{certificate.planName} level of Blockchain</td>
        <td data-label="Amount">{certificate.planName}</td>
        <td data-label="Period" className="courseview">
          <Link to={`/certificate/${certificate.certificateID}`}>
            <button className="view-certificate" style={{border: "none", cursor: "pointer"}}>View Certificate</button>
          </Link>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="4" style={{textAlign:'center'}}>No datas found</td>
    </tr>
  )}
</tbody>

        </table>
      </div>
      <Footer /> 
   
    </div>
  );
}

export default ProfileCertificate;
