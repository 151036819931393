import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import useScrollToTop from '../../../Hooks/useScrollToTop';
import Footer from '../../../Footer/Footer';


function PrivacyPolicy() {
  useScrollToTop()
  return (
    <div className='PrivacyPolicy-container'>
     <div className='PrivacyPolicy-top'>
     <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     </div>
     <div className='PrivacyPolicy-middle'>
   <div className='PrivacyPolicy-main' >
    <h1 style={{fontSize:'3rem',fontWeight:'700px',lineHeight:'1.1',fontFamily:'Poppins'}}>STUDENT PROTECTION AND PRIVACY POLICY</h1>
   <h5>Data Collection and Usage</h5>
   <p> The INOCYX learning platform collects personal information from students for the 
purpose of providing educational services and maintaining student records. The 
INOCYX learning platform will clearly outline what information is collected, how it is 
used, and if any third parties have access to the data. </p>
<h5>Data Security</h5>
<p>The INOCYX learning platform employs industry-standard security measures to 
protect student data from unauthorized access, loss, or misuse. The INOCYX learning 
platform will specify the security measures in place, such as encryption protocols, 
firewalls, and access controls.
</p>

<h5>Consent and Permissions</h5>
<p> The INOCYX learning platform will obtain appropriate consent from students or 
their legal guardians before collecting any personal information. Students have the 
right to withdraw consent and request the deletion of their personal data, subject to 
any legal or contractual obligations.</p>
<h5>Student Rights</h5>
<p>Students have the right to access, rectify, or update their personal information held 
by the learning platform. The INOCYX learning platform will provide a process for 
students to exercise their data rights and handle any data-related inquiries or 
complaints. </p>
<h5>Third-Party Services and Partners
</h5>
<p>If the INOCYX learning platform utilizes third-party services or partners, it will 
clearly disclose these relationships and their data handling practices. The platform will 
ensure that any third-party services or partners adhere to privacy and security 
standards compatible with student protection.</p>
<h5>Data Retention</h5>
<p>The INOCYX learning platform will specify the duration for which student data will 
be retained and the purposes for which it will be used during that period. Upon the 
expiry of the specified retention period, the platform will securely delete or anonymize 
student data.
</p>
<h5>Compliance with Laws and Regulations</h5>
<p> The INOCYX learning platform will comply with applicable data protection laws 
and regulations, including but not limited to the General Data Protection Regulation 
(GDPR) for European Union residents. The INOCYX platform will clearly state its 
compliance with relevant privacy laws and provide necessary disclosures to students.</p>
<h5>Transparency and Notifications</h5>
<p>The INOCYX learning platform will provide clear and transparent information 
about its data practices, including privacy policies and terms of service. Any changes 
to the privacy policy will be communicated to students through appropriate channels.</p>
<h5>Minor's Privacy</h5>
<p>If the INOCYX learning platform serves minors, it will implement additional 
safeguards to protect their privacy, in compliance with applicable laws and 
regulations. Parental consent will be obtained when required for the collection, use, or 
disclosure of personal information of minors.</p>
<h5>Responsible Data Handling
</h5>
<p>The INOCYX learning platform will train its employees and contractors on 
responsible data handling practices to ensure the protection of student information. 
The INOCYX learning platform will regularly assess and review its data protection 
measures to maintain the highest standards of student privacy.</p>
  </div>  
     </div>
     <div className='PrivacyPolicy-bottom'>
  <Footer/>
    </div>
    </div>
  )
}

export default PrivacyPolicy