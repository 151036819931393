import React,{useEffect,useState} from 'react'
import {Box,Typography,Tab,Tabs} from '@mui/material';
import PropTypes from 'prop-types';
import image1 from "../../../Image/Ellipse 56.png";
import "./UserProfile.css"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ProfileOverview from '../ProfileOverview/ProfileOverview';
import ProfileCertificate from '../ProfileCertificate/ProfileCertificate';
import ProfilePurchase from '../ProfilePurchase/ProfilePurchase';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../Constants';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Overview() {
 
  const [value, setValue] = React.useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const checkToken = localStorage.getItem("token");
        if (checkToken) {
          const url = 'https://e-learning-backend-seven.vercel.app/dashboard-profile';
          const emailFromLocal = localStorage.getItem("email");
          console.log("===>", emailFromLocal, checkToken);
          if (emailFromLocal) {
            const response = await fetch(url, {
              method: 'POST',
              body: JSON.stringify({ "email": emailFromLocal }),
              headers: {
                'Content-Type': 'application/json'
              }
            });
  
            const data = await response.json();
            setUser(data?.data);
            console.log("profileData", data?.data);
          }
        }
      } catch (error) {
        console.error(error);
      
      }
    };
  
    fetchData();
  }, []);
  

 const logoutUser = () =>{
  localStorage.clear();
  window.location.href ="/";
}
   

 if (!user) {
   return <div >
    <div className='signin-loader'>
     <div className='signin-title'>
      SignIn...
      </div>     
  <div class="loader">
  <div class="bar1"></div>
  <div class="bar2"></div>
  <div class="bar3"></div>
  <div class="bar4"></div>
  <div class="bar5"></div>
  <div class="bar6"></div>
  <div class="bar7"></div>
  <div class="bar8"></div>
  <div class="bar9"></div>
  <div class="bar10"></div>
  <div class="bar11"></div>
  <div class="bar12"></div>

</div>
    </div>
     </div>;
 }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
           <div className="Address">
          <div className="ttp">
   <Box className="total1">
<div className="bock" />
    <Box className="tpo-1">
    <Link to='/'><KeyboardBackspaceIcon sx={{width:'35px',height:'35px',color:'#622E27',border:'2px solid #622E27',borderRadius:'50%',backgroundColor:'#F1EBCF'}}/></Link> 
     <div className='editprofile-logout'><p  onClick={()=> logoutUser()}>Logout</p></div>
    </Box>
    <Box className="btt-1">
       <Box className="baat1">
          <Box className="bvo">
           
          {user[0]?.profileURL ?(
              <img src={user[0]?.profileURL} alt='PIC' />
            ):(
              <img 
              src={image1} 
                alt=' Profile Picture'
                width="150px"
                height="150px"
              />
            )}
          
          
         </Box>
         <Box className="bv-1">
          <Box className='bv-11'>
          <Box className='bv-0'>
          <Box className="bv-111">
  <span>{user[0]?.firstName} {user[0]?.lastName}</span>
  <p>{user[0]?.email}</p>
</Box>
      
            </Box>
      
         </Box>
       </Box>
     </Box>
          </Box>
         <div className='edit-1'>
            <div className='edit-1-left'>
                <div>
                <h1>My Account</h1>
                </div>
            <div >
            <p>View and Edit your info below</p>
            </div>
            </div>
            
            <div>
            <Link to={`/Editprofile/${user[0]?._id}`} style={{textDecoration:'none'}}>  <div className='editprofile-text'><p>Edit Profile</p></div>  </Link>
            </div>
           
         </div>
       
              </Box>

  </div>
  <div className='Menu-navigation'>
  <Box sx={{ width: '100%' }}>
      <Box className="navi-item">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Overview" {...a11yProps(0)}  sx={{
          fontSize: {
            xs: "12px", 
            sm: "21px", 
            md: "24px", 
            lg: "26.9333px",
          },
          fontWeight: {
            xs: 400, 
            sm: 400, 
           
          },
          fontFamily: 'Poppins',
          color: value === 0 ? '#622E27' : '#C64709',
        }}
        />
          <Tab label="Certifications" {...a11yProps(1)} 
          sx={{
            fontSize: {
              xs: "12px",
              sm: "21px", 
              md: "24px",
              lg: "26.9333px", 
              xl: "22px", 
            },
            fontWeight: {
              xs: 400,  
              sm: 400, 
             
            },
            fontFamily: 'Poppins',
            color: value === 1 ? '#622E27' : '#C64709',
          }}
          />
          <Tab label="Recent Purchase" {...a11yProps(2)}
          sx={{
            fontSize: {
              xs: "12px", 
              sm: "21px", 
              md: "24px", 
              lg: "26.9333px",
              xl: "22px", 
            },
            fontWeight: {
              xs: 400, 
              sm: 400,  
             
            },
            fontFamily: 'Poppins',
            color: value === 2 ? '#622E27' : '#C64709',
          }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} >
       <ProfileOverview/>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <ProfileCertificate/>
      </TabPanel>
      <TabPanel value={value} index={2}>
       <ProfilePurchase/>
      </TabPanel>
    </Box>
  </div>

           </div>
  )
}
  


export default Overview
