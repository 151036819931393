



import React, { useState } from 'react';
import { TextField } from "@mui/material";
import "./ResetPassword.css"
import axios from 'axios';

import { useNavigate, useParams } from 'react-router-dom';


function NewPasswordForm() {
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const { token } = useParams();
 const navigate=useNavigate()
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleRetypePasswordChange = (e) => {
    setRetypePassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== retypePassword) {
      alert('New password and retype password do not match');
    }
    else{
      console.log("New password and retype password do not match")
  }
    try {
      const response = await axios.post("https://e-learning-backend-seven.vercel.app/user-resetpassword", {
        token,
        newPassword: newPassword,
        retypePassword:retypePassword
      });
      console.log(response.data); 
      alert('Your password has been updated successfully');
      navigate('/SignIn')
    } catch (error) {
      
      console.log("An error occurred while updating your password",error)
      alert('An error occurred while updating your password');
    }
  };

  return (
    <div className="reset-main">
      
      <form onSubmit={handleSubmit} className="reset-form">
      <div className="reset-heading">
        <p>Set your password</p>
      </div>
        <TextField
          type="password"
          variant="standard"
          id="new-password"
          sx={{width: "290px"}}
         placeholder='New Password'
          value={newPassword}
          onChange={handleNewPasswordChange}
        />
        <TextField
          type="password"
          sx={{width: "290px"}}
          id="retype-password"
          variant="standard"
         placeholder='Re-Type password'
          value={retypePassword}
          onChange={handleRetypePasswordChange}
          required
        />
        <div>
          <p className="Reset-details">
            <span style={{ color: 'red' }}>*</span> Password must contain a combination of uppercase letters, lowercase letters, numbers, and special characters.
          </p>
        </div>
        <button type="submit" className="Reset-btn">Confirm</button>
      </form>
    </div>
  );
}

export default NewPasswordForm;
