import React, { useEffect ,useState} from 'react';

import Player2 from './Players/Player2'
import Player3 from './Players/Player3'
import "./Proffesnal.css";

import Frame2 from "../Image/Frame 5605.png";
import Frame3 from "../Image/Frame 5606.png";
import Frame4 from "../Image/Frame 5607.png";
import Frame5 from "../Image/Group 5477.png";


function Proffesnal() {

  const [backgroundColor, setBackgroundColor] = useState('transparent');

 

  useEffect(() => {
  
  const timeout = setTimeout(() => {
  
  setBackgroundColor('red');
  
  }, 2400);
  
  
  return () => clearTimeout(timeout);
  
  }, []);
  
  const [background, setBackground] = useState('transparent');
  
   
  
  useEffect(() => {
  
  const timeout = setTimeout(() => {
  
  setBackground('red');
  
  }, 4500);
  
   
  
  // Cleanup the timer on unmount
  
  return () => clearTimeout(timeout);
  
  }, []);

  return (
    <div className='Proffes-container'>
      <div>
        <p className='prof-text'>Chart Your Path to Blockchain Excellence and Acquire a Professional Certificate.</p>
      </div>

      <div className='Proffes-Frame-Cont'>
        <div className='Profes-First-Frames'>
          <img src={Frame2} alt='Frame1' className='proff-image' />
          <p className='Proff-heading'>Register & choose plan</p>
          <p className='Proff-head-text'>
          Register new to begin your transformative journey to find the perfect fit for your goals.
          </p>
        </div>
        {/* <div className='Profes-First-Frames'>
          <p className='Proff-heading'>Choose Plans</p>
          <p className='Proff-head-text'>
          Choose from our diverse plan to find the perfect fit for your goals.
          </p>
          <img src={Frame1} alt='Frame2' className='proff-image' />
        </div> */}
        <div className='Profes-First-Frames'>
        <p className='Proff-heading'>Learn</p>
        <p className='Proff-head-text'>
          Immerse yourself in our extensive resources and crafted courses to gain Quality Learning.
          </p>
          <img src={Frame3} alt='Frame3' className='proff-image' />
          
          
        </div>
       
        <div className='Profes-First-Frames'>
        <img src={Frame4} alt='Frame4' className='proff-image' />
          <p className='Proff-heading'>Complete Test</p>
          <p className='Proff-head-text'>
          Put your newfound expertise to the test with confidence.
          </p>
         
        </div>
   
        <div className='Profes-First-Frames'>
        <p className='Proff-heading'>Get Certification</p>
        <p className='Proff-head-text'>
          Congrats Obtain your well-earned certification in Blockchain Industry.
          </p>
          <img src={Frame5} alt='Frame5' className='proff-image' />
        </div>
      </div>

      <div>
        <p className='proff-con-text'>
          Our team comprises of experts in blockchain technology, who have years
          of experience in the field.
        </p>
      </div>
    </div>
  );
}

export default Proffesnal;







